import { useEffect, useRef, useState } from "react";
import A4BMeeting, { A4BMeetingProvider } from "@a4b/a4b-meeting";
import { FloatingLabel, Spinner, Form, Image, Button } from "react-bootstrap";
import { useNavigate, useSearchParams } from "react-router-dom";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

function detectMobile() {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}

export default function ChamadaConvidado() {
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [searchParams] = useSearchParams();
	const assembleiaId = searchParams.get("assembleiaId");
	const atoId = searchParams.get("atoId");
	const chamadaId = searchParams.get("chamadaId");
	const chamadaToken = searchParams.get("chamadaToken");
	const chamadaAdvogado = searchParams.get("chamadaAdvogado");

	const [formStatus, setFormStatus] = useState(1);
	const [formNome, setFormNome] = useState("");
	const [iframe, setIframe] = useState(true);
	const kickedRef = useRef(true);

	const [data, setData] = useState<AssembleiaGetResponseEntity["Data"]>();
	const [isLoading, setIsLoading] = useState(true);

	const mobile = detectMobile();

	useEffect(() => {
		assembleiaService.getPublica({ assembleiaId: Number(assembleiaId), chamadatoken: chamadaToken! }).then((resp: AssembleiaGetResponseEntity) => {
			if (resp.Result === 1 && resp.Data) {
				setData(resp.Data);
				setIsLoading(false);
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast("Chamada", resp.Message, 5000, "warning");
				handleVoltar();
			}
		});
	}, []);

	useEffect(() => {
		if (formStatus === 0 && data) {
			const ato = data?.atos.find((ato) => {
				return ato.id === Number(atoId);
			});
			var sessionContainer = document.getElementById("zoomMeeting");
			var config = {
				videoSDKJWT: ato?.videoProvider.sessionToken,
				sessionName: ato?.videoProvider.sessionName,
				userName: formNome,
				sessionPasscode: "",
				features: ["video", "audio", "share", "settings"],
				options: { init: { enforceMultipleVideos: true }, audio: {}, video: {}, share: {} },
			};
			setTimeout(() => {
				uitoolkit.joinSession(sessionContainer, config);
				uitoolkit.onSessionJoined(() => {
					setTimeout(() => {
						if (chamadaAdvogado !== "1") {
							let buttonShare = document.querySelector('[aria-label="Start Share"]') as any;
							buttonShare.style.display = "none";
						}
						let button = document.querySelector('[aria-label="Change view"]') as any;
						button.click();
						setTimeout(() => {
							let buttons = document.querySelectorAll("span") as any;
							buttons.forEach((span: any) => {
								if (span.textContent?.includes("Gallery")) {
									span.click();
								}
							});
						}, 100);
					}, 100);
				});
				uitoolkit.onSessionClosed(() => {
					if (kickedRef.current === true) {
						localStorage.clear();
						handleVoltar();
					}
				});
			}, 2000);

			return () => {
				uitoolkit.closeSession(sessionContainer);
			};
		}
	}, [formStatus, data]);

	function handleVoltar() {
		navigate(-1);
	}

	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;

	return (
		<div className="d-flex justify-content-center position-relative" style={{ height: "100dvh" }}>
			{formStatus === 0 ? (
				<>
					{isLoading && (
						<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
							<Spinner className="mb-2" />
							Carregando
						</div>
					)}
					<div className={`d-flex flex-fill ${mobile && "flex-wrap"}`}>
						{iframe && (
							<div
								className="d-flex flex-column flex-fill"
								style={{ width: 0, minWidth: 400, minHeight: 400, background: "#272F31", display: "flex", justifyContent: "space-between", alignItems: "center" }}
							>
								<div className="d-flex align-items-center w-100" style={{ width: "auto", padding: 8, background: "#272F31" }}>
									<Image alt="Von Meeting" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 30 }} />
									<span className="fs-6 fw-medium text-muted">{descricao}</span>
								</div>
								<div id="zoomMeeting" style={{ height: "100%", overflow: "hidden", aspectRatio: 980 / 800 }}></div>
							</div>
						)}
						<div style={{ width: iframe && !mobile ? 400 : "100%", minHeight: 400 }}>
							{!isLoading && (
								<A4BMeeting
									MeetingID={String(chamadaId)}
									MeetingToken={String(chamadaToken)}
									MeetingOwner={false}
									MeetingGuest={true}
									MeetingDate={ato?.dataPrevista}
									// MeetingName={descricao}
									ParticipantID={generateUUID()}
									ParticipantName={formNome}
									ChatEnabled={true}
									PollsEnabled={false}
									LockEnable={true}
									EndBack={true}
									EndBackCallback={() => {
										navigate(`/assembleia/${assembleiaId}`);
									}}
									EndCallback={() => {
										kickedRef.current = false;
										var sessionContainer = document.getElementById("zoomMeeting");
										uitoolkit.closeSession(sessionContainer);
										setIframe(false);
									}}
									Theme={{
										logo: {
											onWhite: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
											onBlack: `${process.env.PUBLIC_URL}/von_meeting_logo_on_black.svg`,
										},
										primary: "#C2F94B",
										primaryText: "#272F31",
										secondary: "#272F31",
										secondaryText: "#FFFFFF",
										danger: "#EB5757",
										dangerText: "#272F31",
										warning: "#F2C94C",
										warningText: "#272F31",
										disabled: "#BDBDBD",
										disabledText: "#272F31",
										background: "#272F31",
										backgroundSidebar: "#fff",
										backgroundControl: "#3B4143",
									}}
									Video={{
										frameRate: 24,
										width: 320,
										height: 240,
									}}
									apiUrl={process.env.REACT_APP_A4B_API_BASE_URL ?? ""}
									wsUrl={process.env.REACT_APP_A4B_WS_URL ?? ""}
									turnUrl={process.env.REACT_APP_A4B_TURN_URL ?? ""}
									Provider={A4BMeetingProvider.local}
									WebRTC={false}
								/>
							)}
						</div>
					</div>
				</>
			) : (
				<div className="d-flex align-items-center">
					<div className="d-flex justify-content-center align-items-center flex-column" style={{ width: 400 }}>
						<Image className="mb-5" src={process.env.PUBLIC_URL + "/von_meeting_logo_on_white.svg"} style={{ height: 60 }} />
						<div className="fw-bold mb-4 fs-4">{ato?.descricao}</div>
						<FloatingLabel controlId="nome" label="Seu Nome" className="mb-4 w-100">
							<Form.Control
								type="text"
								placeholder="Informe aqui o seu nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
							/>
						</FloatingLabel>
						<Button
							type="button"
							size="lg"
							className="w-100"
							style={{ flex: 1, borderRadius: 100 }}
							onClick={() => {
								setFormStatus(0);
							}}
							disabled={formNome.length === 0}
						>
							Entrar
						</Button>
					</div>
				</div>
			)}
		</div>
	);
}

function generateUUID() {
	var d = new Date().getTime();
	var d2 = (typeof performance !== "undefined" && performance.now && performance.now() * 1000) || 0;
	return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
		var r = Math.random() * 16;
		if (d > 0) {
			r = (d + r) % 16 | 0;
			d = Math.floor(d / 16);
		} else {
			r = (d2 + r) % 16 | 0;
			d2 = Math.floor(d2 / 16);
		}
		return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
	});
}
