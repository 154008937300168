import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Form, Spinner, FloatingLabel, Modal, Col, Row } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import DatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import moment, { Moment } from "moment";
import Select from "react-select";
import InputMask from "react-input-mask";
import "react-image-crop/dist/ReactCrop.css";
import AsyncSelect from "react-select/async";

import Layout from "../../components/Layout";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { AsyncFloatingSelectStyle, AsyncSelectStyle } from "../../config/defines";
import AssembleiaService from "../../services/AssembleiaService";
import ClienteService from "../../services/ClienteService";
import { ClienteGetEstadosResponseEntity, ClienteGetResponseEntity, ClientePostRequestEntity, ClientePostResponseEntity } from "../../entities/ClienteEntity";
import ListCard from "../../components/ListCard";
import {
	AssembleiaGetResumoResponseEntity,
	AssembleiaPostAtosRequestEntity,
	AssembleiaPostAtoResponseEntity,
	AssembleiaPostCredoresRequestEntity,
	AssembleiaPostCredoresResponseEntity,
	AssembleiaPostCredorRequestEntity,
	AssembleiaPostDocumentosRequestEntity,
	AssembleiaPostDocumentosResponseEntity,
	AssembleiaPostRequestEntity,
	AssembleiaPostResponseEntity,
	AssembleiaUpdateCredorRequestEntity,
} from "../../entities/AssembleiaEntity";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconMais } from "../../icons/mais.svg";
import { ReactComponent as IconEditar } from "../../icons/editar.svg";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";

const screenConfig = {
	toastTitle: "Assembleia",
	queryId: "assembleia",
	routeBack: "/assembleias",
	routeSubmit: "/assembleiaFormulario/",
};

type Empresa = {
	id: number;
	nome: string;
	cnpj: string;
	arquivos: Arquivo[];
	assembleiaClienteId?: number;
};

type Arquivo = {
	id?: number;
	loading: boolean;
	value: string;
	arquivo: string;
	contentType: string;
	nome: string;
	tipo: string;
	tipoId: number;
	stage?: number;
};

type Ato = {
	id?: number;
	dataHora: Moment;
	quorum: boolean;
};

const formArquivosInicial: Arquivo[] = [
	{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 0, stage: 2, tipo: "Relação de credores" },
	{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 1, stage: 4, tipo: "Plano de Recuperação" },
	{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 2, stage: 4, tipo: "Edital" },
	{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 3, stage: 4, tipo: "Outro" },
];

const formAtosInicial: Ato[] = [
	{ dataHora: moment(), quorum: false },
	{ dataHora: moment(), quorum: false },
];

const formCredoresClassesSelect = [
	{ value: "1", label: "Trabalhista" },
	{ value: "2", label: "Garantia Real" },
	{ value: "3", label: "Quirografária" },
	{ value: "4", label: "ME ou EPP" },
];

export default function AssembleiaFormulario() {
	const assembleiaService = new AssembleiaService();
	const clienteService = new ClienteService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formStage, setFormStage] = useState(0);
	const [formId, setFormId] = useState<any>(id);

	const [formNumeroProcesso, setFormNumeroProcesso] = useState("");
	const [formAdministradorJudicial, setFormAdministradorJudicial] = useState("");
	const [formEmpresas, setFormEmpresas] = useState<Empresa[]>([]);
	const [formArquivos, setFormArquivos] = useState<Arquivo[]>([{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 3, tipo: "Outro" }]);

	const [formEmpresaStatus, setFormEmpresaStatus] = useState(false);
	const [formEmpresaId, setFormEmpresaId] = useState<number>();
	const [formEmpresaNome, setFormEmpresaNome] = useState("");
	const [formEmpresaRazaoSocial, setFormEmpresaRazaoSocial] = useState("");
	const [formEmpresaCNPJ, setFormEmpresaCNPJ] = useState("");
	const [formEmpresaEndereco, setFormEmpresaEndereco] = useState("");
	const [formEmpresaBairro, setFormEmpresaBairro] = useState("");
	const [formEmpresaCEP, setFormEmpresaCEP] = useState("");
	const [formEmpresaEstadoOptions, setFormEmpresaEstadoOptions] = useState<{ label: string; value: string }[]>();
	const [formEmpresaEstado, setFormEmpresaEstado] = useState<{ label: string; value: string }>();
	const [formEmpresaCidade, setFormEmpresaCidade] = useState<{ label: string; value: number }>();
	const [formEmpresaTelefone, setFormEmpresaTelefone] = useState("");
	const [formEmpresaEmail, setFormEmpresaEmail] = useState("");
	const [formEmpresaSite, setFormEmpresaSite] = useState("");

	const [formAtos, setFormAtos] = useState<Ato[]>(formAtosInicial);
	const [formCredoresIndex, setFormCredoresIndex] = useState(0);
	const [formCredoresLoading, setFormCredoresLoading] = useState(0);
	const [formCredoresClasses, setFormCredoresClasses] = useState<any>();
	const [formCredorModal, setFormCredorModal] = useState(false);
	const [formCredorModalEdit, setFormCredorModalEdit] = useState(false);
	const [formCredorModalLoading, setFormCredorModalLoading] = useState(false);
	const [formCredorId, setFormCredorId] = useState<number>();
	const [formCredorNome, setFormCredorNome] = useState("");
	const [formCredorClasse, setFormCredorClasse] = useState<{ label: string; value: string }>();
	const [formCredorTotal, setFormCredorTotal] = useState("");

	useQuery("estados", () => fetchDataEstados());
	const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>([screenConfig.queryId, id], () => fetchData(id), { enabled: id !== undefined });

	const mutationAssembleia = useMutation(mutateAssembleiaData, { onSuccess: mutateAssembleiaSuccess });
	const mutationEmpresa = useMutation(mutateEmpresaData, { onSuccess: mutateEmpresaSuccess });
	const mutationAtos = useMutation(mutateAtosData);
	const mutationDocumentos = useMutation(mutateDocumentosData);

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (!isFetching && !formRefetching) {
			return false;
		}

		let resp: AssembleiaGetResumoResponseEntity = await assembleiaService.getResumo(id);
		if (resp.Result === 1 && resp.Data) {
			let empresas: Empresa[] = [];
			resp.Data.clientes.forEach((cliente) => {
				let arquivos: any[] = [
					{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 0, stage: 2, tipo: "Relação de credores" },
					{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 1, stage: 4, tipo: "Plano de Recuperação" },
					{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 2, stage: 4, tipo: "Edital" },
					{ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 3, stage: 4, tipo: "Outro" },
				];
				cliente.documentos.forEach((documento) => {
					if (documento.tipoDocumentoId === 3) {
						arquivos = [
							...arquivos.slice(0, -1),
							{ id: documento.id, loading: false, value: "", arquivo: "", contentType: "", nome: documento.nome, tipoId: 3, stage: 4, tipo: "Outro" },
							...arquivos.slice(-1),
						];
					} else {
						arquivos.forEach((arquivo) => {
							if (arquivo.tipoId === documento.tipoDocumentoId) {
								arquivo.id = documento.id;
								arquivo.nome = documento.nome;
							}
						});
					}
				});

				empresas.push({
					id: cliente.clienteId,
					nome: cliente.clienteNome,
					cnpj: cliente.cnpj,
					arquivos: arquivos,
					assembleiaClienteId: cliente.assembleiaClienteId,
				});
			});

			let arquivos: Arquivo[] = [];
			resp.Data.documentos?.forEach((documento) => {
				arquivos.push({ id: documento.id, loading: false, value: "", arquivo: "", contentType: "", nome: documento.nome, tipoId: 3, tipo: "Outro" });
			});

			setFormNumeroProcesso(resp.Data.numeroProcesso);
			setFormAdministradorJudicial(resp.Data.administradorJudicial);
			setFormEmpresas(empresas);
			setFormArquivos(arquivos);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	// ASSEMBLEIA
	async function handleSaveAssembleia() {
		setFormSaving(true);

		const data: AssembleiaPostRequestEntity = {
			id: id ?? undefined,
			numeroProcesso: formNumeroProcesso,
			administradorJudicial: formAdministradorJudicial,
		};

		mutationAssembleia.mutate(data);
	}

	async function mutateAssembleiaData(data: AssembleiaPostRequestEntity) {
		return await assembleiaService.post(data);
	}

	function mutateAssembleiaSuccess(resp: AssembleiaPostResponseEntity) {
		if (resp.Result === 1 && resp.Data) {
			setFormId(resp.Data);
			let promises: any = [];

			formEmpresas
				.filter((empresa) => {
					return empresa.assembleiaClienteId === undefined;
				})
				.forEach(async (empresa) => {
					let data = {
						assembleiaId: `${resp.Data}`,
						clienteId: empresa.id,
					};
					promises.push(assembleiaService.postCliente(data));
				});

			Promise.all(promises).then(async (resps) => {
				let status = true;
				let empresasIndex = formEmpresas.findIndex((empresa) => {
					return empresa.assembleiaClienteId === undefined;
				});
				resps.forEach((resp, respIndex) => {
					if (resp.Result === 1 && resp.Data) {
						formEmpresas[empresasIndex + respIndex].assembleiaClienteId = resp.Data;
					} else {
						if (resp.Result === 99) {
							handleLogout();
							navigate("/");
						}
						handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
						status = false;
					}
				});
				if (status) {
					if (id) {
						await handleCredores(formCredoresIndex);
						setFormCredoresLoading(2);
					}
					handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
					setFormStage(2);
					setFormSaving(false);
				}
			});
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			setFormSaving(false);
		}
	}

	// EMPRESA
	async function handleSaveEmpresa() {
		setFormSaving(true);

		const data: ClientePostRequestEntity = {
			id: formEmpresaId ? Number(formEmpresaId) : undefined,
			nomeFantasia: formEmpresaNome,
			razaoSocial: formEmpresaRazaoSocial,
			cnpj: formEmpresaCNPJ,
			endereco: formEmpresaEndereco,
			bairro: formEmpresaBairro,
			cep: formEmpresaCEP,
			cidadeId: formEmpresaCidade?.value || "",
			telefone: formEmpresaTelefone,
			site: formEmpresaSite,
			email: formEmpresaEmail,
		};

		mutationEmpresa.mutate(data);
	}

	async function mutateEmpresaData(data: ClientePostRequestEntity) {
		return await clienteService.post(data);
	}

	function mutateEmpresaSuccess(resp: ClientePostResponseEntity) {
		if (resp.Result === 1 && resp.Data) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);

			let empresa: Empresa = {
				id: resp.Data.clienteId,
				nome: resp.Data.clienteNome,
				cnpj: resp.Data.cnpj,
				arquivos: formArquivosInicial,
			};
			setFormEmpresas((p) => {
				let index = p.findIndex((x) => {
					return x.id === empresa.id;
				});
				if (index >= 0) {
					p[index] = empresa;
				} else {
					p.push(empresa);
				}
				return p;
			});

			setFormEmpresaNome("");
			setFormEmpresaRazaoSocial("");
			setFormEmpresaCNPJ("");
			setFormEmpresaEndereco("");
			setFormEmpresaBairro("");
			setFormEmpresaCEP("");
			setFormEmpresaEstado(undefined);
			setFormEmpresaCidade(undefined);
			setFormEmpresaTelefone("");
			setFormEmpresaEmail("");
			setFormEmpresaSite("");
			setFormStage(0);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		}
		setFormSaving(false);
	}

	// CREDORES
	async function handleCredores(index: number) {
		let respGetCredores = await assembleiaService.getCredores({ assembleiaClienteId: formEmpresas[index].assembleiaClienteId ?? 0 });
		if (respGetCredores.Result !== 1) {
			if (respGetCredores.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, respGetCredores.Message, 5000, "danger");
		} else {
			setFormCredoresClasses(respGetCredores.Data);
			if (respGetCredores.Data?.classes && respGetCredores.Data?.classes.length >= 1) {
				setFormCredoresLoading(2);
			} else {
				setFormCredoresLoading(0);
			}
		}
	}

	async function handleSaveCredores() {
		setFormSaving(true);
		if (formCredoresIndex < formEmpresas.length - 1) {
			await handleCredores(formCredoresIndex + 1);
			setFormCredoresIndex(formCredoresIndex + 1);
		} else {
			if (id) {
				await handleAtos();
			}
			setFormStage(3);
		}
		setFormSaving(false);
	}

	function handleCredorEdit(credor: any, classe: any) {
		setFormCredorModal(true);
		setFormCredorModalEdit(true);
		setFormCredorId(credor.credorId);
		setFormCredorNome(credor.credorNome);
		setFormCredorClasse({ label: classe.classeNome, value: "" });
		setFormCredorTotal(credor.credorTotal);
	}

	async function handleCredorRemove(credorId: number) {
		setFormSaving(true);
		let resp = await assembleiaService.deleteCredor(credorId);
		if (resp.Result !== 1) {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		} else {
			await handleCredores(formCredoresIndex);
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
		}
		setFormSaving(false);
	}

	function handleCloseCredorModal() {
		setFormCredorModal(false);
		setFormCredorModalEdit(false);
		setFormCredorNome("");
		setFormCredorTotal("");
		setFormCredorClasse(undefined);
		setFormCredorModalLoading(false);
		setFormSaving(false);
	}

	// let erros = [];
	// if(formCredorNome === ""){ erros.push("Nome é obrigatório"); }
	// if(formCredorTotal === "" || Number(formCredorTotal) <  0){ erros.push("Valor é obrigatório"); }
	// if(formCredorClasse === undefined){ erros.push("Classe é obrigatório"); }
	// if(erros.length>0){
	//     handleToast("Assembleia", "Informações invalidas: "+erros.join(', '), 5000, "warning");
	//     setFormStatus(true);
	// }else{
	//      setFormCredorModal(false);
	//     setFormCredorNome("");
	//     setFormCredorTotal("");
	//     setFormCredorClasse(undefined);
	//     setFormCredorModalLoading(false);
	//     setFormSaving(false);
	//     setFormStatus(false);
	// }
	async function handleSaveCredorModal() {
		setFormCredorModalLoading(true);

		if (!formCredorModalEdit) {
			const data: AssembleiaPostCredorRequestEntity = {
				assembleiaClienteId: formEmpresas[formCredoresIndex].assembleiaClienteId ?? 0,
				classeId: Number(formCredorClasse?.value),
				nome: formCredorNome,
				valor: Number(formCredorTotal.replaceAll("R$", "").replaceAll(".", "").replaceAll(",", ".").trim()),
			};

			let respPostCredores = await assembleiaService.postCredor(data);
			if (respPostCredores.Result !== 1) {
				if (respPostCredores.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, respPostCredores.Message, 5000, "danger");
			} else {
				await handleCredores(formCredoresIndex);
				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
				setFormCredorModal(false);
				setFormCredorNome("");
				setFormCredorTotal("");
			}
		} else {
			const data: AssembleiaUpdateCredorRequestEntity = {
				credorId: formCredorId ?? 0,
				credorNome: formCredorNome,
				credorValor: Number(formCredorTotal.replaceAll("R$", "").replaceAll(".", "").replaceAll(",", ".").trim()),
			};

			let respPostCredores = await assembleiaService.updateCredor(data);
			if (respPostCredores.Result !== 1) {
				if (respPostCredores.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, respPostCredores.Message, 5000, "danger");
			} else {
				await handleCredores(formCredoresIndex);
				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
				setFormCredorModal(false);
				setFormCredorModalEdit(false);
				setFormCredorNome("");
				setFormCredorTotal("");
			}
		}

		setFormCredorModalLoading(false);
	}

	let formStatus;
	if (formCredorNome === "" || formCredorClasse === undefined || Number(formCredorTotal) < 1) {
		formStatus = true;
	} else {
		formStatus = false;
	}

	// ATOS
	async function handleAtos() {
		let resp = await assembleiaService.getAtos({ assembleiaId: Number(id) ?? 0 });
		if (resp.Result !== 1) {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		} else {
			let atos: Ato[] = [];
			resp.Data?.assembleiaAtos.forEach((ato) => {
				atos.push({ id: ato.id, dataHora: moment(ato.data), quorum: ato.necessitaQuorum });
			});
			setFormAtos(atos);
		}
	}

	async function handleSaveAtos() {
		setFormSaving(true);
		let promises: any = [];

		let data: AssembleiaPostAtosRequestEntity = {
			assembleiaId: formId,
			atos: formAtos.map((ato) => {
				return {
					atoId: ato.id,
					data: ato.dataHora.toISOString(),
				};
			}),
		};

		promises.push(mutationAtos.mutateAsync(data));
		console.log(data);

		Promise.all(promises).then(mutateAtosSuccess);
	}

	async function mutateAtosData(data: AssembleiaPostAtosRequestEntity) {
		return await assembleiaService.postAto(data);
	}

	function mutateAtosSuccess(resps: AssembleiaPostAtoResponseEntity[]) {
		let status = true;
		resps.forEach((resp) => {
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				status = false;
			}
		});
		if (status) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			setFormStage(4);
		}
		setFormSaving(false);
	}

	async function handleAtoRemove(id: number | undefined, index: number) {
		setFormSaving(true);
		if (id) {
			let resp = await assembleiaService.deleteAto(id);
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			} else {
				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			}
		}
		setFormAtos((p) => p.filter((item, itemIndex) => index !== itemIndex));
		setFormSaving(false);
	}

	// DOCUMENTOS
	async function handleSaveDocumentos() {
		setFormSaving(true);
		let promises: any = [];

		formEmpresas.forEach((empresa) => {
			empresa.arquivos
				.filter((item) => {
					return item.stage === 4 && item.nome !== "" && item.id === undefined;
				})
				.forEach((arquivo) => {
					const data: AssembleiaPostDocumentosRequestEntity = {
						assembleiaId: formId,
						assembleiaClienteId: empresa.assembleiaClienteId,
						tipoDocumentoId: arquivo.tipoId,
						nome: arquivo.nome,
						contentType: arquivo.contentType,
						bytes: arquivo.arquivo,
					};

					promises.push(mutationDocumentos.mutateAsync(data));
				});
		});

		formArquivos
			.filter((item) => {
				return item.nome !== "" && item.id === undefined;
			})
			.forEach((arquivo) => {
				const data: AssembleiaPostDocumentosRequestEntity = {
					assembleiaId: formId,
					tipoDocumentoId: arquivo.tipoId,
					nome: arquivo.nome,
					contentType: arquivo.contentType,
					bytes: arquivo.arquivo,
				};

				promises.push(mutationDocumentos.mutateAsync(data));
			});

		Promise.all(promises).then(mutateDocumentosSuccess);
	}

	async function mutateDocumentosData(data: AssembleiaPostDocumentosRequestEntity) {
		return await assembleiaService.postDocumentos(data);
	}

	function mutateDocumentosSuccess(resps: AssembleiaPostDocumentosResponseEntity[]) {
		let status = true;
		resps.forEach((resp) => {
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				setFormSaving(false);
				status = false;
			}
		});
		if (status) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			handleVoltar();
		}
	}

	async function handleDocumentoRemove(documentoId: number | undefined, empresaId: number, documentoIndex: number) {
		setFormSaving(true);
		if (documentoId) {
			let resp = await assembleiaService.deleteDocumento(documentoId);
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			} else {
				setFormEmpresas((p) =>
					p.map((item) => {
						let arquivos = [...item.arquivos];
						if (item.id === empresaId) {
							arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
								return arquivoIndex === documentoIndex ? { ...arquivoItem, id: undefined, loading: false, value: "", arquivo: "", contentType: "", nome: "" } : arquivoItem;
							});
						}
						return { ...item, arquivos: arquivos };
					})
				);

				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			}
		} else {
			setFormEmpresas((p) =>
				p.map((item) => {
					let arquivos = [...item.arquivos];
					if (item.id === empresaId) {
						arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
							return arquivoIndex === documentoIndex ? { ...arquivoItem, id: undefined, loading: false, value: "", arquivo: "", contentType: "", nome: "" } : arquivoItem;
						});
					}
					return { ...item, arquivos: arquivos };
				})
			);
		}
		setFormSaving(false);
	}

	async function handleArquivoRemove(documentoId: number | undefined, documentoIndex: number) {
		setFormSaving(true);
		if (documentoId) {
			let resp = await assembleiaService.deleteDocumento(documentoId);
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			} else {
				setFormArquivos((p) => {
					let arquivos = [...p];
					arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
						return arquivoIndex === documentoIndex ? { ...arquivoItem, id: undefined, loading: false, value: "", arquivo: "", contentType: "", nome: "" } : arquivoItem;
					});
					return arquivos;
				});

				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			}
		} else {
			setFormArquivos((p) => {
				let arquivos = [...p];
				arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
					return arquivoIndex === documentoIndex ? { ...arquivoItem, id: undefined, loading: false, value: "", arquivo: "", contentType: "", nome: "" } : arquivoItem;
				});
				return arquivos;
			});
		}
		setFormSaving(false);
	}

	// FORMULARIO
	function handleVoltar() {
		navigate(screenConfig.routeBack);
	}

	function handleFormCredoresArquivo(event: any) {
		if (event.target.files[0].size > 10485760) {
			handleToast(screenConfig.toastTitle, "Tamanho do arquivo excede o máximo de 10MB", 5000, "danger");
			return;
		}
		setFormCredoresLoading(1);
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = async () => {
			if (typeof reader.result === "string") {
				const data: AssembleiaPostCredoresRequestEntity = {
					assembleiaClienteId: formEmpresas[formCredoresIndex].assembleiaClienteId ?? 0,
					nome: file.name,
					contentType: file.type,
					bytes: reader.result,
				};

				let respPostCredores: AssembleiaPostCredoresResponseEntity = await assembleiaService.postCredores(data);
				if (respPostCredores.Result !== 1) {
					if (respPostCredores.Result === 99) {
						handleLogout();
						navigate("/");
					}
					handleToast(screenConfig.toastTitle, respPostCredores.Message, 5000, "danger");
					setFormCredoresLoading(0);
				} else {
					await handleCredores(formCredoresIndex);
					setFormCredoresLoading(2);
				}
			}
		};
	}

	function handleFormDocumento(event: any, empresaId: number, index: number) {
		if (event.target.files[0].size > 10485760) {
			handleToast(screenConfig.toastTitle, "Tamanho do arquivo excede o máximo de 10MB", 5000, "danger");
			return;
		}
		setFormEmpresas((p) =>
			p.map((item) => {
				let arquivos = [...item.arquivos];
				if (item.id === empresaId) {
					arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
						return { ...arquivoItem, loading: arquivoIndex === index };
					});
				}
				return { ...item, arquivos: arquivos };
			})
		);
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				let arquivo = {
					value: event.target.value,
					arquivo: reader.result,
					contentType: file.type,
					nome: file.name,
					loading: false,
				};
				setFormEmpresas((p) =>
					p.map((item) => {
						let arquivos = [...item.arquivos];
						if (item.id === empresaId) {
							arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
								return arquivoIndex === index ? { ...arquivoItem, ...arquivo } : arquivoItem;
							});
							if (index === arquivos.length - 1) {
								arquivos.push({ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 3, stage: 4, tipo: "Outro" });
							}
						}
						return { ...item, arquivos: arquivos };
					})
				);
			}
		};
	}

	function handleFormArquivo(event: any, index: number) {
		if (event.target.files[0].size > 10485760) {
			handleToast(screenConfig.toastTitle, "Tamanho do arquivo excede o máximo de 10MB", 5000, "danger");
			return;
		}
		setFormArquivos((p) => {
			let arquivos = [...p];
			arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
				return { ...arquivoItem, loading: arquivoIndex === index };
			});
			return arquivos;
		});
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				let arquivo = {
					value: event.target.value,
					arquivo: reader.result,
					contentType: file.type,
					nome: file.name,
					loading: false,
				};
				setFormArquivos((p) => {
					let arquivos = [...p];
					arquivos = arquivos.map((arquivoItem, arquivoIndex) => {
						return arquivoIndex === index ? { ...arquivoItem, ...arquivo } : arquivoItem;
					});
					if (index === arquivos.length - 1) {
						arquivos.push({ loading: false, value: "", arquivo: "", contentType: "", nome: "", tipoId: 3, tipo: "Outro" });
					}
					return arquivos;
				});
			}
		};
	}

	function handleEmpresaSelect(item: any) {
		let empresa: Empresa = {
			id: item.value,
			nome: item.nome,
			cnpj: item.cnpj,
			arquivos: formArquivosInicial,
		};
		setFormEmpresas((p) => [...p, empresa]);
	}

	const handleEmpresasOptions = (inputValue: string, callback: (options: any[]) => void) => {
		if (inputValue.length >= 3) {
			clienteService.autoCompleteClientes(inputValue).then((resp) => {
				let callbackData = [];
				if (resp.Result === 1 && resp.Data) {
					for (let i in resp.Data) {
						callbackData.push({
							label: `${resp.Data[i].clienteNome}${resp.Data[i].cnpj ? ` - ${resp.Data[i].cnpj}` : ""}`,
							value: resp.Data[i].clienteId,
							nome: resp.Data[i].clienteNome,
							cnpj: resp.Data[i].cnpj,
						});
					}
				} else {
					if (resp.Result === 99) {
						handleLogout();
						navigate("/");
					}
					handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
				}
				callback(callbackData);
			});
		}
	};

	async function handleEmpresaEditar(empresaIndex: number) {
		setFormEmpresaStatus(true);
		setFormStage(1);
		setFormEmpresaId(formEmpresas[empresaIndex].id);

		let resp: ClienteGetResponseEntity = await clienteService.get(formEmpresas[empresaIndex].id);
		if (resp.Result === 1 && resp.Data) {
			let uf = resp.Data.uf;
			setFormEmpresaNome(resp.Data.nomeFantasia);
			setFormEmpresaRazaoSocial(resp.Data.razaoSocial);
			setFormEmpresaCNPJ(resp.Data.cnpj);
			setFormEmpresaEndereco(resp.Data.endereco);
			setFormEmpresaBairro(resp.Data.bairro);
			setFormEmpresaCEP(resp.Data.cep);
			setFormEmpresaEstado(
				formEmpresaEstadoOptions?.find((item) => {
					return item.value === uf;
				})
			);
			setFormEmpresaCidade({ label: `${resp.Data.cidadeNome}`, value: Number(resp.Data.cidadeId) });
			setFormEmpresaTelefone(resp.Data.telefone);
			setFormEmpresaEmail(resp.Data.email);
			setFormEmpresaSite(resp.Data.site);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			setFormStage(0);
		}

		setFormEmpresaStatus(false);

		return true;
	}

	async function handleEmpresaRemover(empresaIndex: number, assembleiaClienteId: number | undefined) {
		setFormSaving(true);
		if (assembleiaClienteId !== undefined) {
			let resp = await assembleiaService.deleteCliente(assembleiaClienteId);
			if (resp.Result !== 1) {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			} else {
				handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			}
		}
		setFormEmpresas((p) => p.filter((item, itemIndex) => empresaIndex !== itemIndex));
		setFormSaving(false);
	}

	async function fetchDataEstados() {
		let resp: ClienteGetEstadosResponseEntity = await clienteService.getEstados();
		if (resp.Result === 1 && resp.Data) {
			setFormEmpresaEstadoOptions(
				resp.Data.map((item) => {
					return { label: `${item.key}`, value: item.value };
				})
			);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	const handleCidadesOptions = (inputValue: string, callback: (options: any[]) => void) => {
		clienteService.autoCompleteCidades(inputValue, formEmpresaEstado?.value).then((resp) => {
			let callbackData = [];
			if (resp.Result === 1 && resp.Data) {
				for (let i in resp.Data) {
					callbackData.push({ label: `${resp.Data[i].key}`, value: resp.Data[i].value });
				}
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
			}
			callback(callbackData);
		});
	};

	if (formEmpresas.length === 0) {
		formStatus = true;
	} else {
		formStatus = false;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center">
				<IconSVG path={IconAssembleias} width={24} height={24} className="me-1" /> Formulário de Assembleia
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} /> Voltar
					</Button>
				</div>
			</h5>

			{/* ASSEMBLEIA */}
			{formStage === 0 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<FloatingLabel controlId="numeroProcesso" label="Número do processo" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o número do processo"
									value={formNumeroProcesso}
									onChange={(event) => {
										setFormNumeroProcesso(event.target.value);
									}}
									disabled={isFetching}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="administradorJudicial" label="Administrador Judicial" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o administrador judicial"
									value={formAdministradorJudicial}
									onChange={(event) => {
										setFormAdministradorJudicial(event.target.value);
									}}
									disabled={isFetching}
								/>
							</FloatingLabel>

							<h5 className="mt-4 mb-4 d-flex align-items-center">
								Selecione empresas que fazem parte desta assembléia
								<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
									<Button
										className="p-3"
										variant="primary"
										onClick={() => {
											setFormStage(1);
										}}
										style={{ width: 180 }}
									>
										Cadastrar Empresa
									</Button>
								</div>
							</h5>

							<Form.Group className="mb-3" controlId="enviarPara">
								<AsyncSelect
									placeholder={"Buscar"}
									value={null}
									filterOption={(option) => {
										return formEmpresas.findIndex((empresa) => {
											return empresa.id === Number(option.value);
										}) >= 0
											? false
											: true;
									}}
									noOptionsMessage={(v) => {
										return v.inputValue.length === 0 ? "Digite para buscar a opção desejada" : "Nenhuma opção disponivel";
									}}
									loadingMessage={() => {
										return (
											<>
												<Spinner size="sm" /> Carregando opções
											</>
										);
									}}
									loadOptions={handleEmpresasOptions}
									onChange={(value: any) => {
										handleEmpresaSelect(value);
									}}
									styles={AsyncSelectStyle}
									isDisabled={isFetching}
								/>
							</Form.Group>

							<div className="mb-3">
								{formEmpresas.map((empresa, empresaIndex) => {
									return (
										<ListCard key={empresaIndex}>
											<ListCard.Content>
												<ListCard.Item>
													<ListCard.Title>Empresa</ListCard.Title>
													<ListCard.Text>{empresa.nome}</ListCard.Text>
												</ListCard.Item>
												<ListCard.Item>
													<ListCard.Title>CNPJ</ListCard.Title>
													<ListCard.Text>{empresa.cnpj}</ListCard.Text>
												</ListCard.Item>
											</ListCard.Content>
											<ListCard.Action>
												<Button
													variant="light"
													className={`p-1 me-2`}
													onClick={() => {
														handleEmpresaEditar(empresaIndex);
													}}
													disabled={formSaving}
												>
													<IconSVG path={IconEditar} width={32} height={32} />
												</Button>
												<Button
													variant="light"
													className={`p-1 me-2`}
													onClick={() => {
														handleEmpresaRemover(empresaIndex, empresa.assembleiaClienteId);
													}}
													disabled={formSaving}
												>
													<IconSVG path={IconFechar} width={32} height={32} />
												</Button>
											</ListCard.Action>
										</ListCard>
									);
								})}
							</div>

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									handleSaveAssembleia();
								}}
								style={{ width: 180 }}
								disabled={formStatus}
							>
								{formSaving && <Spinner animation="border" size="sm" className="me-2" />} Próximo
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* EMPRESA */}
			{formStage === 1 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4 d-flex align-items-center">
								Preencha as informações da empresa
								{formEmpresaStatus && <Spinner size="sm" className="ms-1" variant="secondary" />}
							</h5>

							<FloatingLabel controlId="empresaNome" label="Nome da Empresa" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o nome da empresa"
									value={formEmpresaNome}
									onChange={(event) => {
										setFormEmpresaNome(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaRazaoSocial" label="Razão social" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui a razão social"
									value={formEmpresaRazaoSocial}
									onChange={(event) => {
										setFormEmpresaRazaoSocial(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaCNPJ" label="CNPJ" className="mb-3">
								<InputMask
									mask="99.999.999/9999-99"
									type="text"
									className="form-control"
									placeholder="Informe aqui o CNPJ"
									value={formEmpresaCNPJ}
									onChange={(event) => {
										setFormEmpresaCNPJ(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaEndereco" label="Endereço" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o endereço"
									value={formEmpresaEndereco}
									onChange={(event) => {
										setFormEmpresaEndereco(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaBairro" label="Bairro" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o bairro"
									value={formEmpresaBairro}
									onChange={(event) => {
										setFormEmpresaBairro(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaCEP" label="CEP" className="mb-3">
								<InputMask
									mask="99999-999"
									type="text"
									className="form-control"
									placeholder="Informe aqui o CEP"
									value={formEmpresaCEP}
									onChange={(event) => {
										setFormEmpresaCEP(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaEstado" label="Estado" className="mb-3 floating-select">
								<Select
									placeholder={"Selecione o estado"}
									defaultValue={formEmpresaEstado}
									value={formEmpresaEstado}
									noOptionsMessage={() => {
										return "Nenhuma opção disponivel";
									}}
									options={formEmpresaEstadoOptions}
									styles={AsyncFloatingSelectStyle}
									onChange={(value) => {
										if (value) {
											setFormEmpresaCidade(undefined);
											setFormEmpresaEstado(value);
										}
									}}
									isDisabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaCidade" label="Cidade" className="mb-3 floating-select">
								<AsyncSelect
									placeholder={"Selecione a cidade"}
									value={formEmpresaCidade}
									noOptionsMessage={(v) => {
										return v.inputValue.length === 0 ? "Digite para buscar a opção desejada" : "Nenhuma opção disponivel";
									}}
									loadingMessage={() => {
										return (
											<>
												<Spinner size="sm" /> Carregando opções
											</>
										);
									}}
									loadOptions={handleCidadesOptions}
									onChange={(value: any) => {
										setFormEmpresaCidade(value);
									}}
									styles={AsyncFloatingSelectStyle}
									isDisabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaTelefone" label="Telefone" className="mb-3">
								<InputMask
									mask="(99) 9999-9999"
									type="text"
									className="form-control"
									placeholder="Informe aqui o telefone"
									value={formEmpresaTelefone}
									onChange={(event) => {
										setFormEmpresaTelefone(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaEmail" label="Email" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o email"
									value={formEmpresaEmail}
									onChange={(event) => {
										setFormEmpresaEmail(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="empresaSite" label="Site" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o site"
									value={formEmpresaSite}
									onChange={(event) => {
										setFormEmpresaSite(event.target.value);
									}}
									disabled={formEmpresaStatus}
								/>
							</FloatingLabel>

							<Button className="me-2 p-3" variant="primary" type="button" onClick={handleSaveEmpresa} style={{ width: 180 }} disabled={formSaving || formEmpresaStatus}>
								{formSaving && <Spinner animation="border" size="sm" className="me-2" />} Salvar
							</Button>
							<Button
								className="me-2 p-3"
								variant="danger"
								type="button"
								onClick={() => {
									setFormStage(0);
								}}
								style={{ width: 180 }}
								disabled={formSaving || formEmpresaStatus}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* CREDORES */}
			{formStage === 2 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4">
								Importar relação de credores - <span className="text-underline">{formEmpresas[formCredoresIndex].nome}</span>
								<div className="mt-2 fs-6 fw-light">
									São aceitos apenas arquivos em formato <b className="fw-bold">CSV ou XLSX</b>
									<br />
									Tamanho máximo por arquivo: <b className="fw-bold">10MB</b>
									<br />
									Baixe um modelo do arquivo{" "}
									<a href={process.env.PUBLIC_URL + "/static/relacao_de_credores_modelo.csv"} download className="fw-bold text-black">
										clicando aqui
									</a>
								</div>
							</h5>

							{formCredoresLoading !== 2 && (
								<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
									<Form.Label className="mb-0">
										<div className="mt-2">
											<div
												className={`img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-4 fw-bold`}
												style={{ width: 160, height: 180, borderStyle: "dashed" }}
											>
												{formCredoresLoading === 0 && <IconSVG path={IconMais} width={46} height={46} className="mb-3" />}
												{formCredoresLoading === 1 && <Spinner className="mb-2" />}
												Relação de credores
											</div>
										</div>
										<Form.Control
											className="d-none"
											type="file"
											accept=".csv,.xlsx"
											placeholder="arquivo"
											value={""}
											onChange={(e) => {
												handleFormCredoresArquivo(e);
											}}
										/>
									</Form.Label>
								</Form.Group>
							)}

							{formCredoresLoading === 2 && (
								<>
									<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
										<Form.Label className="mb-0">
											<div className="mt-2">
												<div
													className={`img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-center form-control p-4 fw-bold`}
													style={{ width: 160, height: 180, borderStyle: "dashed" }}
												>
													{<IconSVG path={IconMais} width={46} height={46} className="mb-3" />}
													Relação de credores
												</div>
											</div>
											<Form.Control
												className="d-none"
												type="file"
												accept=".csv,.xlsx"
												placeholder="arquivo"
												value={""}
												onChange={(e) => {
													handleFormCredoresArquivo(e);
												}}
											/>
										</Form.Label>
									</Form.Group>
									{formCredoresClasses.classes.map((classe: any, classeIndex: any) => {
										return (
											<div key={classeIndex}>
												<h5 className="mb-1 d-flex align-items-center">
													{classe.classeNome}
													<div className="fs-6 fw-light float-right ms-auto">Total {classe.classeTotal}</div>
												</h5>
												<div className="mb-4">
													{classe.credores.map((credor: any, credorIndex: any) => {
														return (
															<ListCard key={`${classeIndex}-${credorIndex}`}>
																<ListCard.Content>
																	<ListCard.Item>
																		<ListCard.Title>Credor</ListCard.Title>
																		<ListCard.Text>{credor.credorNome}</ListCard.Text>
																	</ListCard.Item>
																	<ListCard.Item>
																		<ListCard.Title>Total</ListCard.Title>
																		<ListCard.Text>{credor.credorTotal}</ListCard.Text>
																	</ListCard.Item>
																</ListCard.Content>
																<ListCard.Action>
																	<Button
																		variant="light"
																		className={`p-1 me-2`}
																		onClick={() => {
																			handleCredorEdit(credor, classe);
																		}}
																		disabled={formSaving}
																	>
																		<IconSVG path={IconEditar} width={32} height={32} />
																	</Button>
																	<Button
																		variant="light"
																		className={`p-1 me-2`}
																		onClick={() => {
																			handleCredorRemove(credor.credorId);
																		}}
																		disabled={formSaving}
																	>
																		<IconSVG path={IconFechar} width={32} height={32} />
																	</Button>
																</ListCard.Action>
															</ListCard>
														);
													})}
												</div>
											</div>
										);
									})}

									<div className="mb-3 d-flex align-items-center">
										<div className="fs-6 fw-light float-right ms-auto">Total {formCredoresClasses.total}</div>
									</div>
								</>
							)}
							<Button
								className="p-3 d-flex align-items-center mb-3"
								variant="light"
								type="button"
								onClick={() => {
									setFormCredorModal(true);
								}}
								disabled={formSaving}
							>
								<IconSVG path={IconMais} width={24} height={24} className="me-2" /> Adicionar credor
							</Button>

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									handleSaveCredores();
								}}
								style={{ width: 180 }}
								disabled={formSaving || formCredoresLoading !== 2}
							>
								{formSaving && <Spinner animation="border" size="sm" className="me-2" />} Próximo
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* ATOS */}
			{formStage === 3 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4">Adicionar atos</h5>

							{formAtos.map((ato, atoIndex) => {
								return (
									<Row key={atoIndex}>
										<Col>
											<FloatingLabel controlId="atoData" label="Data" className="mb-3 floating-date-picker">
												<DatePicker
													className="form-control"
													calendarClassName="dropdown-menu show shadow-lg"
													weekDayClassName={(date) => {
														return "fw-light";
													}}
													dayClassName={(date) => {
														return `btn btn-sm ${date.toDateString() === ato.dataHora.toDate().toDateString() ? "btn-primary" : "btn-light"} `;
													}}
													monthClassName={(date) => {
														return "bg-danger";
													}}
													timeClassName={(date) => {
														return "bg-danger";
													}}
													popperModifiers={[
														{
															name: "arrow",
															options: {
																padding: ({ popper, reference, placement }) => ({
																	right: Math.min(popper.width, reference.width) - Math.min(popper.width, reference.width) + 24,
																}),
															},
														},
													]}
													selected={ato.dataHora.toDate()}
													onChange={(date) => {
														setFormAtos((p) =>
															p.map((item, itemIndex) => {
																return atoIndex === itemIndex ? { ...item, dataHora: moment(date) } : item;
															})
														);
													}}
													timeInputLabel="Hora:"
													dateFormat="dd/MM/yyyy HH:mm"
													showTimeInput
													customInput={<InputMask mask="99/99/9999 99:99" type="text" className="form-control" />}
												/>
											</FloatingLabel>
										</Col>
										<Col md="auto">
											<Button
												className="mb-3 p-3 d-flex align-items-center"
												variant="light"
												type="button"
												onClick={() => {
													handleAtoRemove(ato.id, atoIndex);
												}}
												disabled={formSaving}
											>
												<IconSVG path={IconFechar} width={24} height={24} />
											</Button>
										</Col>
									</Row>
								);
							})}

							<Button
								className="mb-4 p-3 d-flex align-items-center"
								variant="light"
								type="button"
								onClick={() => {
									setFormAtos((p) => [...p, { dataHora: moment(), quorum: false }]);
								}}
								disabled={formSaving}
							>
								<IconSVG path={IconMais} width={24} height={24} className="me-2" /> Adicionar ato
							</Button>

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									handleSaveAtos();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Próximo
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* DOCUMENTOS */}
			{formStage === 4 && (
				<Card className="mb-4">
					<Card.Body>
						<Form>
							<h5 className="mb-4">
								Adicionar documentos
								<div className="mt-2 fs-6 fw-light">
									São aceitos apenas arquivos em formato <b className="fw-bold">PDF</b>
									<br />
									Tamanho máximo por arquivo: <b className="fw-bold">10MB</b>
								</div>
							</h5>

							<div className="mb-1 fs-6">Documentos da assembleia</div>
							<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
								{formArquivos.map((arquivo, arquivoIndex) => {
									return (
										<Form.Label
											className="mb-0"
											key={arquivoIndex}
											style={{ cursor: "pointer" }}
											onClick={(event) => {
												if (arquivo.nome) {
													event.preventDefault();
													handleArquivoRemove(arquivo.id, arquivoIndex);
												}
											}}
										>
											<div className="mt-2">
												<div
													className={`form-file img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`}
													style={{ width: 160, height: 180, borderStyle: "dashed" }}
												>
													{arquivo.loading ? (
														<Spinner className="mb-2" />
													) : (
														<>
															<IconSVG path={arquivo.nome ? IconFechar : IconMais} width={46} height={46} style={{ minHeight: 46 }} />
															{arquivo.nome && (
																<span className="text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden" }}>
																	{arquivo.nome}
																</span>
															)}
															{arquivo.tipo}
														</>
													)}
												</div>
											</div>
											<Form.Control
												className="d-none"
												type="file"
												accept="application/pdf"
												placeholder="arquivo"
												value={arquivo.value}
												onChange={(e) => {
													handleFormArquivo(e, arquivoIndex);
												}}
											/>
										</Form.Label>
									);
								})}
							</Form.Group>

							{formEmpresas.map((empresa, empresaIndex) => {
								return (
									<div key={empresaIndex}>
										<div className="mb-1 fs-6">{empresa.nome}</div>
										<Form.Group className="mb-4 d-flex" style={{ gap: 24 }}>
											{empresa.arquivos.map((arquivo, arquivoIndex) => {
												if (arquivo.stage !== 4) {
													return false;
												}
												return (
													<Form.Label
														className="mb-0"
														key={`${empresaIndex}-${arquivoIndex}`}
														style={{ cursor: "pointer" }}
														onClick={(event) => {
															if (arquivo.nome) {
																event.preventDefault();
																handleDocumentoRemove(arquivo.id, empresa.id, arquivoIndex);
															}
														}}
													>
														<div className="mt-2">
															<div
																className={`form-file img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`}
																style={{ width: 160, height: 180, borderStyle: "dashed" }}
															>
																{arquivo.loading ? (
																	<Spinner className="mb-2" />
																) : (
																	<>
																		<IconSVG path={arquivo.nome ? IconFechar : IconMais} width={46} height={46} style={{ minHeight: 46 }} />
																		{arquivo.nome && (
																			<span className="text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden" }}>
																				{arquivo.nome}
																			</span>
																		)}
																		{arquivo.tipo}
																	</>
																)}
															</div>
														</div>
														<Form.Control
															className="d-none"
															type="file"
															accept="application/pdf"
															placeholder="arquivo"
															value={arquivo.value}
															onChange={(e) => {
																handleFormDocumento(e, empresa.id, arquivoIndex);
															}}
														/>
													</Form.Label>
												);
											})}
										</Form.Group>
									</div>
								);
							})}

							<Button
								className="me-2 p-3"
								variant="primary"
								type="button"
								onClick={() => {
									handleSaveDocumentos();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								{formSaving && <Spinner animation="border" size="sm" className="me-2" />} Salvar
							</Button>
							<Button
								className="me-2 p-3"
								variant="light"
								type="button"
								onClick={() => {
									handleVoltar();
								}}
								style={{ width: 180 }}
								disabled={formSaving}
							>
								Cancelar
							</Button>
						</Form>
					</Card.Body>
				</Card>
			)}

			{/* CREDOR MODAL */}
			<Modal show={formCredorModal} onHide={handleCloseCredorModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>{formCredorModalEdit ? "Editar" : "Adicionar"} Credor</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{!formCredorModalLoading ? (
						<>
							<FloatingLabel controlId="credorNome" label="Nome do credor" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Informe aqui o nome do credor"
									value={formCredorNome}
									onChange={(event) => {
										setFormCredorNome(event.target.value);
									}}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="credorClasse" label="Classe" className="mb-3 floating-select">
								<Select
									placeholder={"Selecione a classe"}
									defaultValue={formCredorClasse}
									value={formCredorClasse}
									noOptionsMessage={() => {
										return "Nenhuma opção disponivel";
									}}
									options={formCredoresClassesSelect}
									styles={AsyncFloatingSelectStyle}
									isDisabled={formCredorModalEdit}
									onChange={(value) => {
										if (value) {
											setFormCredorClasse(value);
										}
									}}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="credorTotal" label="Total" className="mb-3">
								<Form.Control
									type="text"
									maxLength={24}
									placeholder="Informe aqui o total"
									value={formCredorTotal}
									onChange={(event) => {
										const inputValue = event.target.value;
										const unmaskedValue = Number(inputValue.replace(/[^0-9]/g, "")) / 100;
										const formattedValue = formatCurrency(unmaskedValue);
										event.target.value = formattedValue;
										function formatCurrency(value: number): string {
											const formattedValue = value.toLocaleString("pt-BR", {
												style: "currency",
												currency: "BRL",
												minimumFractionDigits: 2,
												maximumFractionDigits: 2,
											});
											return formattedValue;
										}
										setFormCredorTotal(event.target.value);
									}}
								/>
							</FloatingLabel>
						</>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</Modal.Body>
				{!formCredorModalLoading && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseCredorModal} style={{ width: 100 }}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleSaveCredorModal} style={{ width: 100 }} disabled={formStatus}>
							Salvar
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}
