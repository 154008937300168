import { useEffect } from "react";
import { Col, Container, Form, Image, Row, Spinner } from "react-bootstrap";

export default function DocumentoAssinado() {
	useEffect(() => {
		setTimeout(() => {
			window.close();
		}, 5000);
	}, []);

	return (
		<Container fluid id="login-container" style={{ minHeight: "100dvh" }}>
			<div id="login-background">
				<Image src={process.env.PUBLIC_URL + "/login_background2.webp"} style={{ objectFit: "cover", width: "100%", height: "100%" }} />
			</div>
			<Row className="align-items-center" style={{ minHeight: "100dvh", display: "flex" }}>
				<Col
					xs={{ span: 10, offset: 1 }}
					sm={{ span: 10, offset: 1 }}
					md={{ span: 10, offset: 1 }}
					lg={{ span: 10, offset: 1 }}
					xl={{ span: 10, offset: 2 }}
					xxl={{ span: 10, offset: 2 }}
					className="my-5"
					style={{ maxWidth: 700 }}
				>
					<Image id="login-logo" className="mb-4" src={process.env.PUBLIC_URL + "/logo_escuro.png"} style={{ height: 130 }} />

					<Form className="login-form">
						<div className="d-flex align-items-center gap-2 mb-4 text-white fs-5" style={{ fontFamily: "Satoshi" }}>
							<Spinner size="sm" /> Processando documento...
						</div>
					</Form>
				</Col>
			</Row>
		</Container>
	);
}
