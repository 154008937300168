import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import { Button, Card, Form, Spinner, FloatingLabel } from "react-bootstrap";
import InputMask from "react-input-mask";
import Icon from "@mdi/react";
import { mdiChevronLeft } from "@mdi/js";
import Select from "react-select";
import AsyncSelect from "react-select/async";

import Layout from "../../components/Layout";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { ConfiguracaoEntity, ConfiguracaoGetResponseEntity, ConfiguracaoPostResponseEntity } from "../../entities/ConfiguracaoEntity";
import { configuracaoGetEmpresa, configuracaoPostEmpresa } from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { AsyncFloatingSelectStyle } from "../../config/defines";
import ClienteService from "../../services/ClienteService";
import { ClienteGetEstadosResponseEntity } from "../../entities/ClienteEntity";

import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconConfig } from "../../icons/configuracoes.svg";

export default function ConfiguracaoFormulario() {
	const clienteService = new ClienteService();

	const navigate = useNavigate();

	const { empresaId } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const [formStatus, setFormStatus] = useState(empresaId ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);

	const [formNome, setFormNome] = useState("");
	const [formRazaoSocial, setFormRazaoSocial] = useState("");
	const [formCNPJ, setFormCNPJ] = useState("");
	const [formEndereco, setFormEndereco] = useState("");
	const [formBairro, setFormBairro] = useState("");
	const [formCEP, setFormCEP] = useState("");
	const [formEstadoOptions, setFormEstadoOptions] = useState<{ label: string; value: string }[]>();
	const [formEstado, setFormEstado] = useState<{ label: string; value: string }>();
	const [formCidade, setFormCidade] = useState<{ label: string; value: number }>();
	const [formTelefone, setFormTelefone] = useState("");
	const [formEmail, setFormEmail] = useState("");
	const [formSite, setFormSite] = useState("");
	const [formUF, setFormUF] = useState<string>();
	const [formDocuSign, setFormDocuSign] = useState<ConfiguracaoEntity["documentSignProvider"]>({
		isProduction: false,
		integrationKey: "",
		userId: "",
		accountId: "",
		privateKey: "",
	});
	const [formZoom, setFormZoom] = useState<ConfiguracaoEntity["meetingVideoProvider"]>({
		name: "",
		videoSdkKey: "",
		videoSdkSecret: "",
	});
	const queryEstados = useQuery("estados", () => fetchDataEstados());
	const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>(["configuracao", empresaId], () => fetchData(empresaId), {
		enabled: empresaId !== undefined && queryEstados.isLoading !== true,
	});
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	useEffect(() => {
		if (formUF && formEstadoOptions) {
			setFormEstado(
				formEstadoOptions?.find((item) => {
					return item.value === formUF;
				})
			);
		}
	}, [formUF, formEstadoOptions]);

	async function fetchData(empresaId: any) {
		if ((formStatus && !formRefetching) || empresaId === undefined) {
			return false;
		}

		let resp: ConfiguracaoGetResponseEntity = await configuracaoGetEmpresa(empresaId);
		if (resp.Result === 1 && resp.Data) {
			setFormUF(resp.Data.uf);
			setFormNome(resp.Data.empresaNome);
			setFormRazaoSocial(resp.Data.razaoSocial);
			setFormCNPJ(resp.Data.cnpj);
			setFormEndereco(resp.Data.endereco);
			setFormBairro(resp.Data.bairro);
			setFormCEP(resp.Data.cep);
			setFormCidade({ label: `${resp.Data.cidadeNome}`, value: Number(resp.Data.cidadeId) });
			setFormTelefone(resp.Data.telefone);
			setFormEmail(resp.Data.email);
			setFormSite(resp.Data.site);
			if (resp.Data.documentSignProvider) {
				setFormDocuSign(resp.Data.documentSignProvider);
			}
			if (resp.Data.meetingVideoProvider) {
				setFormZoom(resp.Data.meetingVideoProvider);
			}
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Configuração", resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function fetchDataEstados() {
		let resp: ClienteGetEstadosResponseEntity = await clienteService.getEstados();
		if (resp.Result === 1 && resp.Data) {
			setFormEstadoOptions(
				resp.Data.map((item) => {
					return { label: `${item.key}`, value: item.value };
				})
			);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Configuração", resp.Message, 5000, "warning");
			handleVoltar();
		}

		return true;
	}

	async function mutateData(data: ConfiguracaoEntity) {
		let resp = await configuracaoPostEmpresa(data);
		return resp;
	}

	function mutateSuccess(resp: ConfiguracaoPostResponseEntity) {
		if (resp.Result === 1) {
			queryClient.invalidateQueries(["configuracoesEmpresas"]);
			queryClient.invalidateQueries(["configuracao", resp.Data?.empresaId]);
			handleToast("Configuração", "Informações salvas com sucesso!", 5000);
			navigate("/configuracaoFormulario/" + resp.Data?.empresaId);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast("Configuração", resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/configuracoesEmpresas");
	}

	async function handleSave() {
		setFormSaving(true);

		const data: ConfiguracaoEntity = {
			empresaId: empresaId ? Number(empresaId) : undefined,
			empresaNome: formNome,
			nomeFantasia: formRazaoSocial,
			razaoSocial: formRazaoSocial,
			cnpj: formCNPJ,
			endereco: formEndereco,
			bairro: formBairro,
			cep: formCEP,
			cidadeId: formCidade?.value || "",
			telefone: formTelefone,
			site: formSite,
			email: formEmail,
			documentSignProvider: formDocuSign,
			meetingVideoProvider: formZoom,
		};

		mutation.mutate(data);
	}

	const handleCidadesOptions = (inputValue: string, callback: (options: any[]) => void) => {
		clienteService.autoCompleteCidades(inputValue, formEstado?.value).then((resp) => {
			let callbackData = [];
			if (resp.Result === 1 && resp.Data) {
				for (let i in resp.Data) {
					callbackData.push({ label: `${resp.Data[i].key}`, value: resp.Data[i].value });
				}
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
			}
			callback(callbackData);
		});
	};

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center fw-light">
				<IconSVG path={IconConfig} width={24} height={24} className="me-1" /> Formulário de Configuração
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} />
					</Button>
				</div>
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form>
						<FloatingLabel controlId="empresaNome" label="Nome da Empresa" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome da empresa"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaRazaoSocial" label="Razão social" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui a razão social"
								value={formRazaoSocial}
								onChange={(event) => {
									setFormRazaoSocial(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaCNPJ" label="CNPJ" className="mb-3">
							<InputMask
								mask="99.999.999/9999-99"
								type="text"
								className="form-control"
								placeholder="Informe aqui o CNPJ"
								value={formCNPJ}
								onChange={(event) => {
									setFormCNPJ(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaEndereco" label="Endereço" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o endereço"
								value={formEndereco}
								onChange={(event) => {
									setFormEndereco(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaBairro" label="Bairro" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o bairro"
								value={formBairro}
								onChange={(event) => {
									setFormBairro(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaCEP" label="CEP" className="mb-3">
							<InputMask
								mask="99999-999"
								type="text"
								className="form-control"
								placeholder="Informe aqui o CEP"
								value={formCEP}
								onChange={(event) => {
									setFormCEP(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaEstado" label="Estado" className="mb-3 floating-select">
							<Select
								placeholder={"Selecione o estado"}
								defaultValue={formEstado}
								value={formEstado}
								noOptionsMessage={() => {
									return "Nenhuma opção disponivel";
								}}
								options={formEstadoOptions}
								styles={AsyncFloatingSelectStyle}
								onChange={(value) => {
									if (value) {
										setFormCidade(undefined);
										setFormEstado(value);
									}
								}}
								isDisabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaCidade" label="Cidade" className="mb-3 floating-select">
							<AsyncSelect
								placeholder={"Selecione a cidade"}
								value={formCidade}
								noOptionsMessage={(v) => {
									return v.inputValue.length === 0 ? "Digite para buscar a opção desejada" : "Nenhuma opção disponivel";
								}}
								loadingMessage={() => {
									return (
										<>
											<Spinner size="sm" /> Carregando opções
										</>
									);
								}}
								loadOptions={handleCidadesOptions}
								onChange={(value: any) => {
									setFormCidade(value);
								}}
								styles={AsyncFloatingSelectStyle}
								isDisabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaTelefone" label="Telefone" className="mb-3">
							<InputMask
								mask="(99) 9999-9999"
								type="text"
								className="form-control"
								placeholder="Informe aqui o telefone"
								value={formTelefone}
								onChange={(event) => {
									setFormTelefone(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaEmail" label="Email" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o email"
								value={formEmail}
								onChange={(event) => {
									setFormEmail(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="empresaSite" label="Site" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o site"
								value={formSite}
								onChange={(event) => {
									setFormSite(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<h5 className="mt-4 mb-3 d-flex align-items-center fw-light">
							DocuSign <small className="text-muted ms-2">(Para utilizar as credências padrão deixe os campos em branco)</small>
						</h5>

						<Form.Group className="mb-3" controlId="documentSignProvider.isProduction">
							<Form.Label>Produção</Form.Label>
							<Form.Check
								type="switch"
								label={formDocuSign.isProduction ? "Sim" : "Não"}
								className="d-flex gap-2"
								checked={formDocuSign.isProduction}
								onChange={(event) => {
									setFormDocuSign({ ...formDocuSign, isProduction: event.currentTarget.checked });
								}}
								disabled={!formStatus}
							/>
						</Form.Group>

						<FloatingLabel controlId="documentSignProvider.integrationKey" label="Chave de Integração" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui a chave de integração"
								value={formDocuSign.integrationKey}
								onChange={(event) => {
									setFormDocuSign({ ...formDocuSign, integrationKey: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>
						<FloatingLabel controlId="documentSignProvider.userId" label="ID de Usuário" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o ID de usuário"
								value={formDocuSign.userId}
								onChange={(event) => {
									setFormDocuSign({ ...formDocuSign, userId: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="documentSignProvider.accountId" label="ID de Conta" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o ID de conta"
								value={formDocuSign.accountId}
								onChange={(event) => {
									setFormDocuSign({ ...formDocuSign, accountId: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="documentSignProvider.privateKey" label="Chave Privada" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui a chave privada"
								value={formDocuSign.privateKey}
								onChange={(event) => {
									setFormDocuSign({ ...formDocuSign, privateKey: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<h5 className="mt-4 mb-3 d-flex align-items-center fw-light">
							Provedor Video <small className="text-muted ms-2">(Para utilizar as credências padrão deixe os campos em branco)</small>
						</h5>

						<FloatingLabel controlId="meetingVideoProvider.name" label="Name" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formZoom.name}
								onChange={(event) => {
									setFormZoom({ ...formZoom, name: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="meetingVideoProvider.videoSdkKey" label="Video SDK Key" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o Video SDK Key"
								value={formZoom.videoSdkKey}
								onChange={(event) => {
									setFormZoom({ ...formZoom, videoSdkKey: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						<FloatingLabel controlId="meetingVideoProvider.videoSdkSecret" label="Video SDK Secret" className="mb-3">
							<Form.Control
								type="text"
								placeholder="Informe aqui o Video SDK Secret"
								value={formZoom.videoSdkSecret}
								onChange={(event) => {
									setFormZoom({ ...formZoom, videoSdkSecret: event.currentTarget.value });
								}}
								disabled={!formStatus}
							/>
						</FloatingLabel>

						{!formStatus ? (
							<Button
								className="me-2 p-3"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
								style={{ width: 180 }}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 p-3" variant="primary" type="button" onClick={handleSave} style={{ width: 180 }} disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{empresaId && (
									<Button className="me-2 p-3" variant="danger" type="button" onClick={handleCancel} style={{ width: 180 }} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}
