import { useEffect, useRef, useState } from "react";
import { Image, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import A4BMeeting, { A4BMeetingProvider, MeetingPollVoterType } from "@a4b/a4b-meeting";
import uitoolkit from "@zoom/videosdk-ui-toolkit";
import "@zoom/videosdk-ui-toolkit/dist/videosdk-ui-toolkit.css";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import { AssembleiaGetResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

enum tipoPergunta {
	Multiplas = "representative",
}

function detectMobile() {
	const toMatch = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
	return toMatch.some((toMatchItem) => {
		return navigator.userAgent.match(toMatchItem);
	});
}

export default function Chamada() {
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { assembleiaId, atoId } = useParams();
	const { user, handleLogout } = useAuth();
	const { handleToast } = useToast();

	const [iframe, setIframe] = useState(true);
	const kickedRef = useRef(true);

	const [data, setData] = useState<AssembleiaGetResponseEntity["Data"]>();
	const [isLoading, setIsLoading] = useState(true);

	const mobile = detectMobile();

	useEffect(() => {
		assembleiaService.get({ assembleiaId: Number(assembleiaId) }).then((resp: AssembleiaGetResponseEntity) => {
			if (resp.Result === 1 && resp.Data) {
				setData(resp.Data);
				setIsLoading(false);
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast("Chamada", resp.Message, 5000, "warning");
				handleVoltar();
			}
		});
	}, []);

	useEffect(() => {
		if (!isLoading && data) {
			const ato = data.atos.find((ato) => {
				return ato.id === Number(atoId);
			});
			var sessionContainer = document.getElementById("zoomMeeting");
			var config = {
				videoSDKJWT: ato?.videoProvider.sessionToken,
				sessionName: ato?.videoProvider.sessionName,
				userName: user?.usuario.nome,
				sessionPasscode: "",
				features: user?.usuario.grupoId ? ["video", "audio", "share", "users", "settings"] : ["video", "audio", "share", "settings"],
				options: { init: { enforceMultipleVideos: true }, audio: {}, video: {}, share: {} },
			};
			setTimeout(() => {
				uitoolkit.joinSession(sessionContainer, config);
				uitoolkit.onSessionJoined(() => {
					setTimeout(() => {
						if (!user?.usuario.grupoId) {
							let buttonShare = document.querySelector('[aria-label="Start Share"]') as any;
							buttonShare.style.display = "none";
						}
						let button = document.querySelector('[aria-label="Change view"]') as any;
						button.click();
						setTimeout(() => {
							let buttons = document.querySelectorAll("span") as any;
							buttons.forEach((span: any) => {
								if (span.textContent?.includes("Gallery")) {
									span.click();
								}
							});
						}, 100);
					}, 100);
				});
				uitoolkit.onSessionClosed(() => {
					if (kickedRef.current === true) {
						localStorage.clear();
						handleVoltar();
					}
				});
			}, 2000);

			return () => {
				uitoolkit.closeSession(sessionContainer);
			};
		}
	}, [isLoading, data]);

	function handleVoltar() {
		navigate(-1);
	}

	const ato = data?.atos.find((ato) => {
		return ato.id === Number(atoId);
	});
	const descricao = `${data?.clientes[0].clienteNome} ${data && data.clientes.length > 1 ? "e outros" : ""} - ${ato?.descricao}`;
	let grupos: string[] = [];
	if (user?.usuario.grupoId) {
		grupos =
			data?.clientes.map((cliente) => {
				return cliente.clienteNome;
			}) || [];
	} else {
		data?.clientes.forEach((cliente) => {
			if (
				cliente.classes.some((classe) => {
					return classe.credores.some((credor) => {
						return credor.representando;
					});
				})
			) {
				if (!grupos.includes(cliente.clienteNome)) {
					grupos.push(cliente.clienteNome);
				}
			}
		});
	}
	const perguntas = ato?.perguntas.map((pergunta) => {
		return {
			externalId: String(pergunta.id),
			text: pergunta.texto,
			voterType: tipoPergunta[pergunta.tipoPergunta as keyof typeof tipoPergunta],
			options: pergunta.opcoes.map((opcao) => {
				return {
					id: String(opcao.id),
					text: opcao.nome,
				};
			}),
			group: `${pergunta.clienteNome}`,
			tag: pergunta.tag,
		};
	});
	const tags = data?.quoruns.map((quorun) => {
		return quorun.valor;
	});

	return (
		<div className="d-flex justify-content-center position-relative" style={{ height: "100dvh" }}>
			{isLoading && (
				<div className="position-absolute d-flex justify-content-center align-items-center flex-column bg-white" style={{ inset: 0, zIndex: 100 }}>
					<Spinner className="mb-2" />
					Carregando
				</div>
			)}
			<div className={`d-flex flex-fill ${mobile && "flex-wrap"}`}>
				{iframe && (
					<div
						className="d-flex flex-column flex-fill"
						style={{ width: 0, minWidth: 400, minHeight: 400, background: "#272F31", display: "flex", justifyContent: "space-between", alignItems: "center" }}
					>
						<div className="d-flex align-items-center w-100" style={{ width: "auto", padding: 8, background: "#272F31" }}>
							<Image alt="Von Meeting" src={process.env.PUBLIC_URL + "/logo.png"} style={{ height: 30 }} />
							<span className="fs-6 fw-medium text-muted">{descricao}</span>
						</div>
						<div id="zoomMeeting" style={{ width: "fit-content", height: "fit-content", minWidth: 300, minHeight: 300, overflow: "hidden", aspectRatio: 980 / 800 }}></div>
					</div>
				)}
				<div style={{ width: iframe && !mobile ? 400 : "100%", minHeight: 400 }}>
					{!isLoading && (
						<A4BMeeting
							MeetingID={String(ato?.chamadaId)}
							MeetingToken={String(ato?.chamadaToken)}
							MeetingOwner={user?.usuario.grupoId ? true : false}
							MeetingDate={ato?.dataPrevista}
							// MeetingName={descricao}
							ParticipantID={String(user?.usuario.id)}
							ParticipantName={String(user?.usuario.nome)}
							ParticipantEmail={String(user?.usuario.email)}
							ScreenShare={user?.usuario.grupoId ? true : false}
							ChatEnabled={true}
							PollsEnabled={true}
							ParticipantsEnabled={false}
							DocumentsEnabled={true}
							LockEnable={true}
							Polls={perguntas}
							PollsConfig={{
								voterTypes: false,
								voterTypeDefault: MeetingPollVoterType.representative,
								groups: grupos,
								groupsPlaceholder: "Selecione a empresa",
								tags: tags,
								tagsPlaceholder: "Selecione o quórum",
							}}
							EndBack={true}
							EndBackCallback={() => {
								navigate(`/assembleia/${assembleiaId}`);
							}}
							EndCallback={() => {
								kickedRef.current = false;
								var sessionContainer = document.getElementById("zoomMeeting");
								uitoolkit.closeSession(sessionContainer);
								setIframe(false);
							}}
							Theme={{
								logo: {
									onWhite: `${process.env.PUBLIC_URL}/von_meeting_logo_on_white.svg`,
									onBlack: `${process.env.PUBLIC_URL}/von_meeting_logo_on_black.svg`,
								},
								primary: "#C2F94B",
								primaryText: "#272F31",
								secondary: "#272F31",
								secondaryText: "#FFFFFF",
								danger: "#EB5757",
								dangerText: "#272F31",
								warning: "#F2C94C",
								warningText: "#272F31",
								disabled: "#BDBDBD",
								disabledText: "#272F31",
								background: "#272F31",
								backgroundSidebar: "#fff",
								backgroundControl: "#3B4143",
							}}
							Video={{
								frameRate: 24,
								width: 320,
								height: 240,
							}}
							apiUrl={process.env.REACT_APP_A4B_API_BASE_URL ?? ""}
							wsUrl={process.env.REACT_APP_A4B_WS_URL ?? ""}
							turnUrl={process.env.REACT_APP_A4B_TURN_URL ?? ""}
							Provider={A4BMeetingProvider.local}
							WebRTC={false}
						/>
					)}
				</div>
			</div>
		</div>
	);
}
