import { useState } from "react";
import { Badge, Button, Form, Modal, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiInformationOutline } from "@mdi/js";
import moment from "moment";
import { Color } from "react-bootstrap/esm/types";

import ListCard from "../../components/ListCard";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import CredenciamentoService from "../../services/CredenciamentoService";
import HomologacaoService from "../../services/HomologacaoService";
import { CredenciamentoGetResponseEntity, CredenciamentoPostDocumentosRequestEntity, CredenciamentoPostDocumentosResponseEntity } from "../../entities/CredenciamentoEntity";
import { HomologacaoGetDocumentoResponseEntity } from "../../entities/HomologacaoEntity";
import Layout from "../../components/Layout";
import IconSVG from "../../components/IconSVG";
import { ReactComponent as IconCheck } from "../../icons/check.svg";
import { ReactComponent as IconDownload } from "../../icons/download.svg";
import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";
import { ReactComponent as IconMais } from "../../icons/mais.svg";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";

const screenConfig = {
	toastTitle: "Assembleia Credenciada",
	queryId: "credenciada",
	routeBack: "/assembleiasCredenciadas",
};

type Arquivo = {
	credorId: string;
	id: string;
	value: string;
	arquivo: string;
	contentType: string;
	nome: string;
	tipo: string;
	tipoId: string;
	loading: boolean;
};

const arquivoInicial: Arquivo = { credorId: "", id: "", value: "", arquivo: "", contentType: "", nome: "", tipo: "", tipoId: "", loading: false };

export default function AssembleiaCredenciada() {
	const credenciamentoService = new CredenciamentoService();
	const homologacaoService = new HomologacaoService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const [numeroProcesso, setNumeroProcesso] = useState("");
	const [administradorJudicial, setAdministradorJudicial] = useState("");
	const [formClientes, setFormClientes] = useState<unknown[]>();
	const [baixarLoading, setBaixarLoading] = useState(false);
	const [enviarDocumentoModal, setEnviarDocumentoModal] = useState(false);
	const [enviarDocumentoLoadingModal, setEnviarDocumentoLoadingModal] = useState(false);
	const [enviarDocumentoArquivo, setEnviarDocumentoArquivo] = useState<Arquivo>(arquivoInicial);

	const [removeId, setRemoveId] = useState<number>();
	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);

	const queryAssembleia = useQuery([screenConfig.queryId, id], () => fetchData(id), { enabled: id !== undefined });

	async function fetchData(id: any) {
		let resp: CredenciamentoGetResponseEntity = await credenciamentoService.get({ assembleiaId: id });
		if (resp.Result === 1 && resp.Data) {
			setNumeroProcesso(resp.Data.numeroProcesso);
			setAdministradorJudicial(resp.Data.administradorJudicial);
			setFormClientes(resp.Data.clientes);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return resp;
	}

	function handleVoltar(): void {
		navigate(screenConfig.routeBack);
	}

	function handleDetalhes(): void {
		navigate(`/assembleia/${id}`);
	}

	async function handleEnviarDocumento(credorId: string, id: string, tipo: string, tipoId: string) {
		setEnviarDocumentoModal(true);
		setEnviarDocumentoArquivo((p) => {
			return { ...p, credorId: credorId, id: id, tipo: tipo, tipoId: tipoId };
		});
	}

	async function handleBaixarDocumento(id: number) {
		setBaixarLoading(true);
		let resp: HomologacaoGetDocumentoResponseEntity = await homologacaoService.getDocumento({ documentoId: id });
		if (resp.Result === 1 && resp.Data) {
			let link = document.createElement("a");
			link.download = resp.Data.nome;
			link.href = `data:${resp.Data.contentType};base64,${resp.Data.bytes}`;
			link.click();
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		}
		setBaixarLoading(false);
	}

	function handleCloseEnviarDocumentoModal() {
		setEnviarDocumentoModal(false);
	}

	function handleArquivo(event: any) {
		setEnviarDocumentoArquivo((p) => {
			return { ...p, loading: true };
		});
		let reader = new FileReader();
		let file = event.target.files[0];
		reader.readAsDataURL(file);
		reader.onloadend = () => {
			if (typeof reader.result === "string") {
				let arquivo = {
					value: event.target.value,
					arquivo: reader.result,
					contentType: file.type,
					nome: file.name,
					loading: false,
				};
				setEnviarDocumentoArquivo((p) => {
					return { ...p, ...arquivo };
				});
			}
		};
	}

	async function handleEnviarDocumentoModal() {
		setEnviarDocumentoLoadingModal(true);

		let data: CredenciamentoPostDocumentosRequestEntity = [
			{
				documentoProcurador: false,
				credorDocumentoId: Number(enviarDocumentoArquivo.id),
				tipoArquivoId: Number(enviarDocumentoArquivo.tipoId),
				nome: enviarDocumentoArquivo.nome,
				contentType: enviarDocumentoArquivo.contentType,
				bytes: enviarDocumentoArquivo.arquivo,
				credorRepresentadoId: Number(enviarDocumentoArquivo.credorId),
			},
		];

		let resp: CredenciamentoPostDocumentosResponseEntity = await credenciamentoService.postDocumentos(data);
		if (resp.Result !== 1) {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		} else {
			queryAssembleia.refetch();
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
		}

		setEnviarDocumentoArquivo(arquivoInicial);
		setEnviarDocumentoLoadingModal(false);
		setEnviarDocumentoModal(false);
	}

	function handleClickRemoverCredor(id: number) {
		setRemoveId(id);
		setRemoveModal(true);
	}

	async function handleRemoverCredor() {
		if (removeId) {
			setRemoveLoadingModal(true);
			let resp = await credenciamentoService.delete(removeId);
			if (resp.Result === 1) {
				queryAssembleia.refetch();
			} else {
				if (resp.Result === 99) {
					handleLogout();
					navigate("/");
				}
				handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
			}
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	function handleCloseModalRemoverCredor() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	let formStatus;
	if (enviarDocumentoArquivo.arquivo === "") {
		formStatus = true;
	} else {
		formStatus = false;
	}

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center">
				<IconSVG path={IconAssembleias} width={24} height={24} className="me-1" /> Assembleia Credenciada
				{(queryAssembleia.isLoading || queryAssembleia.isFetching || queryAssembleia.isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} /> Voltar
					</Button>
				</div>
			</h5>

			<div className="mb-4">
				<ListCard.Item>
					<ListCard.Title>Nome da empresa</ListCard.Title>
					<ListCard.Text skeleton={queryAssembleia.isLoading ? queryAssembleia.isLoading : undefined}>
						{queryAssembleia.data?.Data?.clientes
							.map((cliente) => {
								return cliente.clienteNome;
							})
							.join(", ") ?? "..."}
					</ListCard.Text>
				</ListCard.Item>
			</div>

			<div className="mb-4">
				<ListCard.Item>
					<ListCard.Title>Número do processo</ListCard.Title>
					<ListCard.Text skeleton={queryAssembleia.isLoading ? queryAssembleia.isLoading : undefined}>{queryAssembleia.data?.Data?.numeroProcesso ?? "..."}</ListCard.Text>
				</ListCard.Item>
			</div>

			<div className="mb-4">
				<ListCard.Item>
					<ListCard.Title>Administrador Judicial</ListCard.Title>
					<ListCard.Text skeleton={queryAssembleia.isLoading ? queryAssembleia.isLoading : undefined}>{queryAssembleia.data?.Data?.administradorJudicial ?? "..."}</ListCard.Text>
				</ListCard.Item>
			</div>

			{queryAssembleia.data?.Data?.clientes &&
				queryAssembleia.data?.Data?.clientes.map((cliente: any, clienteIndex: number) => {
					return (
						<div className="mb-4" key={clienteIndex}>
							<h5 className="mb-1 d-flex align-items-center">{cliente.clienteNome}</h5>

							{cliente.credores.map((credor: any, credorIndex: number) => {
								return (
									<div className="mb-3" key={credorIndex}>
										<div className="d-flex align-items-center my-2 fs-6" style={{ fontWeight: 500 }}>
											{credor.credorNome}
											<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
												<Button variant="light" className={`p-1 me-2`} onClick={() => handleClickRemoverCredor(credor.credorRepresentadoId)}>
													<IconSVG path={IconFechar} width={24} height={24} className="me-2" /> Remover credor
												</Button>
											</div>
										</div>
										{credor.documentos.map((documento: any, documentoIndex: number) => {
											let badgeText: Color = "dark";

											return (
												<ListCard key={documentoIndex} onClick={() => {}}>
													<ListCard.Content>
														<ListCard.Item>
															<ListCard.Title>Tipo de documento</ListCard.Title>
															<ListCard.Text>{documento.tipoArquivoNome}</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Title>Nome</ListCard.Title>
															<ListCard.Text>{documento.nome}</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Title>Data de envio</ListCard.Title>
															<ListCard.Text>{moment(documento.dataEnvio).format("DD/MM/yyyy HH:mm")}</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Title>Status</ListCard.Title>
															<ListCard.Text style={{ display: "block", textOverflow: "unset", wordBreak: "unset" }}>
																<Badge text={badgeText} bg="" style={{ backgroundColor: documento.credorArquivoStatusCor }}>
																	{documento.credorArquivoStatusNome}
																</Badge>
															</ListCard.Text>
														</ListCard.Item>
														<ListCard.Item>
															<ListCard.Text>&nbsp;</ListCard.Text>
															{documento.credorArquivoStatusNome === "Reprovado" ? (
																<OverlayTrigger placement={"bottom"} overlay={<Tooltip>{documento.mensagem}</Tooltip>}>
																	<Icon path={mdiInformationOutline} size={0.9} />
																</OverlayTrigger>
															) : (
																""
															)}
														</ListCard.Item>
													</ListCard.Content>
													<ListCard.Action>
														<Button
															variant="light"
															className={`p-1 me-2 ms-5`}
															title="Baixar"
															onClick={() => {
																handleEnviarDocumento(credor.credorRepresentadoId, documento.credorDocumentoId, documento.tipoArquivoNome, documento.tipoArquivoId);
															}}
															style={{ visibility: documento.credorArquivoStatusId === 4 ? "initial" : "hidden" }}
														>
															<IconSVG path={IconCheck} width={32} height={32} className="me-2" /> Adicionar documento
														</Button>
														<Button
															variant="light"
															className={`p-1 me-2`}
															title="Baixar"
															onClick={() => {
																handleBaixarDocumento(documento.credorDocumentoId);
															}}
															disabled={baixarLoading}
														>
															<IconSVG path={IconDownload} width={32} height={32} className="me-2" /> Baixar
														</Button>
													</ListCard.Action>
												</ListCard>
											);
										})}
									</div>
								);
							})}
						</div>
					);
				})}

			<div>
				<Button className="mt-2 me-2 p-3 mb-5" variant="light" type="button" onClick={handleDetalhes}>
					<IconSVG path={IconAssembleias} width={32} height={32} className="me-2" /> Assembleia
				</Button>
			</div>

			<Modal show={removeModal} onHide={handleCloseModalRemoverCredor} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Credor</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover este credor?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModalRemoverCredor}>
							Cancelar
						</Button>
						<Button
							variant="danger"
							onClick={() => {
								handleRemoverCredor();
							}}
						>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal show={enviarDocumentoModal} onHide={handleCloseEnviarDocumentoModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>Adicionar Documento</Modal.Title>
				</Modal.Header>
				<Modal.Body className="d-flex justify-content-center">
					{!enviarDocumentoLoadingModal ? (
						<>
							<Form.Group className="mb-4 d-flex">
								<Form.Label className="mb-0" style={{ cursor: "pointer" }}>
									<div className="mt-2">
										<div
											className={`form-file img-fluid img-thumbnail text-center align-items-center d-flex flex-column justify-content-around form-control p-2 fw-bold`}
											style={{ width: 160, height: 180, borderStyle: "dashed" }}
										>
											{enviarDocumentoArquivo.loading ? (
												<Spinner className="mb-2" />
											) : (
												<>
													{enviarDocumentoArquivo.nome ? (
														<span className="mb-2 text-muted fw-normal" style={{ wordBreak: "break-all", maxHeight: "100%", overflow: "hidden" }}>
															{enviarDocumentoArquivo.nome}
														</span>
													) : (
														<IconSVG path={IconMais} width={46} height={46} className="mb-4" />
													)}
													<div>{enviarDocumentoArquivo.tipo}</div>
												</>
											)}
										</div>
									</div>
									<Form.Control
										accept="application/pdf"
										className="d-none"
										type="file"
										placeholder="arquivo"
										value={enviarDocumentoArquivo.value}
										onChange={(e) => {
											handleArquivo(e);
										}}
									/>
								</Form.Label>
							</Form.Group>
						</>
					) : (
						<Spinner />
					)}
				</Modal.Body>
				{!enviarDocumentoLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseEnviarDocumentoModal}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleEnviarDocumentoModal} disabled={formStatus}>
							Adicionar
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}
