import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Card, Col, FloatingLabel, Form, Modal, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiFileDocumentAlert } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { AssembleiaGetResponseEntity, AssembleiaPostPerguntaRequestEntity, AssembleiaPostPerguntaResponseEntity } from "../../entities/AssembleiaEntity";
import AssembleiaService from "../../services/AssembleiaService";

import AssembleiaDocumentoCard from "../../components/AssembleiaDocumentoCard";
import AssembleiaClienteCard from "../../components/AssembleiaClienteCard";
import AssembleiaAtoCard from "../../components/AssembleiaAtoCard";
import AssembleiaPerguntasCard from "../../components/AssembleiaPerguntasCard";
import AssembleiaCredoresCard from "../../components/AssembleiaCredoresCard";
import ListCard from "../../components/ListCard";
import IconSVG from "../../components/IconSVG";
import Layout from "../../components/Layout";
import Tabs from "../../components/Tabs";

import { ReactComponent as IconAssembleias } from "../../icons/assembleias.svg";
import { ReactComponent as IconCheck } from "../../icons/check.svg";
import { ReactComponent as IconMais } from "../../icons/mais.svg";
import { ReactComponent as IconEditar } from "../../icons/editar.svg";
import { ReactComponent as IconFechar } from "../../icons/fechar.svg";
import { AsyncFloatingSelectStyle } from "../../config/defines";

const screenConfig = {
	toastTitle: "Assembleia",
	queryId: "assembleia",
	routeBack: -1,
};

type AssembleiaPerguntasData = {
	atoId: number | undefined;
	texto: string;
	opcoes: {
		texto: string;
	}[];
};

const perguntaOpcoesPadrao = [{ texto: "Sim" }, { texto: "Não" }, { texto: "Abstenção" }];

export default function Assembleia() {
	const assembleiaService = new AssembleiaService();

	const navigate = useNavigate();
	const { id } = useParams();
	const { user } = useAuth();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const [formStage, setFormStage] = useState(0);
	const [formClienteIndex, setFormClienteIndex] = useState<number>();
	const [formAtoIndex, setFormAtoIndex] = useState<number>();

	const [formPergunta, setFormPergunta] = useState<AssembleiaPerguntasData>({ atoId: undefined, texto: "", opcoes: perguntaOpcoesPadrao });
	const [formPerguntaEmpresa, setFormPerguntaEmpresa] = useState<{ label: string; value: string }>();
	const [formPerguntaTag, setFormPerguntaTag] = useState<{ label: string; value: string }>();
	const [formPerguntaPersonalizar, setFormPerguntaPersonalizar] = useState(false);
	const [formPerguntaModal, setFormPerguntaModal] = useState(false);
	const [formSaving, setFormSaving] = useState(false);

	const { data, isLoading, isFetching, isRefetching, refetch } = useQuery([screenConfig.queryId, id], () => fetchData(id), { enabled: id !== undefined });
	const mutationPergunta = useMutation(mutatePerguntaData, { onSuccess: mutatePerguntaSuccess });

	const [formActive, setFormActive] = useState("atos");

	async function fetchData(assembleiaId: any) {
		let resp: AssembleiaGetResponseEntity = await assembleiaService.get({ assembleiaId: Number(assembleiaId) });
		if (resp.Result === 1 && resp.Data) {
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		return resp;
	}

	function handleVoltar(): void {
		if (formStage !== 1) {
			navigate(screenConfig.routeBack);
		} else {
			setFormStage(0);
		}
	}

	function handleCredenciamento() {
		navigate("/assembleiaCredenciamentoFormulario/" + id);
	}

	function handleCredenciamentoAcompanhar() {
		navigate("/assembleiaCredenciada/" + id);
	}

	async function handleFormPerguntaModal() {
		if (data?.Data?.atos && formAtoIndex !== undefined) {
			setFormPergunta({ atoId: data?.Data?.atos[formAtoIndex].id, texto: "", opcoes: perguntaOpcoesPadrao });
		}
		setFormPerguntaModal(true);
	}

	async function handleFecharModal() {
		setFormPerguntaModal(false);
	}

	function handleNovaOpcao() {
		setFormPergunta((antiga) => {
			let newOptions = [...antiga.opcoes];
			newOptions.push({ texto: "" });
			return { ...antiga, opcoes: newOptions };
		});
	}

	function handleOpcaoRemove(index: number) {
		setFormPergunta((p) => {
			return { ...p, opcoes: p.opcoes.filter((item, itemIndex) => index !== itemIndex) };
		});
	}

	function handleSalvarPergunta() {
		setFormSaving(true);

		const data: AssembleiaPostPerguntaRequestEntity = {
			atoId: Number(formPergunta.atoId),
			texto: formPergunta.texto,
			opcoes: formPergunta.opcoes,
			clienteId: Number(formPerguntaEmpresa?.value),
			tag: formPerguntaTag?.label,
		};

		mutationPergunta.mutate(data);
	}

	async function mutatePerguntaData(data: AssembleiaPostPerguntaRequestEntity) {
		return await assembleiaService.postPergunta(data);
	}

	function mutatePerguntaSuccess(resp: AssembleiaPostPerguntaResponseEntity) {
		if (resp.Result === 1 && resp.Data) {
			handleToast(screenConfig.toastTitle, "Informações salvas com sucesso!", 5000);
			refetch();
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(screenConfig.toastTitle, resp.Message, 5000, "danger");
		}
		setFormSaving(false);
		handleFecharModal();
	}

	function handlePersonalizar() {
		setFormPergunta((antiga) => {
			return { ...antiga, opcoes: [{ texto: "" }] };
		});
		setFormPerguntaPersonalizar(true);
	}

	const formPerguntaEmpresaSelect = data?.Data?.clientes.map((cliente) => {
		return { label: cliente.clienteNome, value: `${cliente.clienteId}` };
	});

	const formPerguntaTagSelect = data?.Data?.quoruns.map((cliente) => {
		return { label: cliente.valor, value: cliente.chave };
	});

	return (
		<Layout>
			<h5 className="mt-4 mb-4 d-flex align-items-center">
				<IconSVG path={IconAssembleias} width={24} height={24} className="me-1" /> Assembleia
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} /> Voltar
					</Button>
				</div>
			</h5>

			{formStage === 0 && (
				<Card>
					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Nome da empresa</ListCard.Title>
							<ListCard.Text skeleton={isLoading ? isLoading : undefined}>
								{data?.Data?.clientes
									.map((cliente) => {
										return cliente.clienteNome;
									})
									.join(", ") ?? "..."}
							</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Número do processo</ListCard.Title>
							<ListCard.Text skeleton={isLoading ? isLoading : undefined}>{data?.Data?.numeroProcesso ?? "..."}</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className="mb-4">
						<ListCard.Item>
							<ListCard.Title>Administrador judicial</ListCard.Title>
							<ListCard.Text skeleton={isLoading ? isLoading : undefined}>{data?.Data?.administradorJudicial ?? "..."}</ListCard.Text>
						</ListCard.Item>
					</div>

					<div className={`d-flex justify-content-start mb-2`} style={{ marginTop: -10, marginBottom: -10 }}>
						<Tabs
							active={formActive}
							options={[
								{ value: "atos", label: "Atos" },
								{ value: "credores", label: "Credores" },
								{ value: "documentos", label: "Documentos / Relatórios" },
							]}
							onChange={(value) => setFormActive(value)}
						/>
					</div>

					<div>
						{formActive === "atos" &&
							data?.Data?.atos.map((ato, index) => {
								return (
									<AssembleiaAtoCard
										key={index}
										index={index}
										data={{ ...ato, assembleiaId: Number(id) }}
										onClick={(index) => {
											setFormStage(2);
											setFormAtoIndex(index);
										}}
									/>
								);
							})}

						{formActive === "credores" &&
							data?.Data?.clientes.map((cliente, index) => {
								return (
									<AssembleiaClienteCard
										key={index}
										data={cliente}
										index={index}
										onClick={(index) => {
											setFormStage(1);
											setFormClienteIndex(index);
										}}
									/>
								);
							})}

						{formActive === "documentos" && data?.Data?.documentos.length === 0 && (
							<div className="text-center p-4 d-flex justify-content-center align-items-center mt-2 text-muted">
								<Icon path={mdiFileDocumentAlert} size={1} className="me-2" />
								Sem documentos vinculados
							</div>
						)}

						{formActive === "documentos" &&
							data?.Data?.documentos.length !== 0 &&
							data?.Data?.documentos.map((documento, index) => {
								return <AssembleiaDocumentoCard key={index} data={documento} />;
							})}
					</div>

					{!user?.usuario.grupoId && (
						<div>
							<Button
								className="mt-4 me-2 p-3"
								variant="light"
								type="button"
								style={{ width: "300px" }}
								onClick={() => {
									handleCredenciamento();
								}}
							>
								<IconSVG path={IconMais} width={32} height={32} className="me-2" /> Credenciar
							</Button>
							{data?.Data?.credenciamentoStatus && (
								<Button
									className="mt-4 me-2 p-3"
									variant="light"
									type="button"
									style={{ width: "300px" }}
									onClick={() => {
										handleCredenciamentoAcompanhar();
									}}
								>
									<IconSVG path={IconCheck} width={32} height={32} className="me-2" /> Acompanhar
								</Button>
							)}
						</div>
					)}
				</Card>
			)}

			{formStage === 1 && (
				<div>
					<Col className="mb-4">
						<Col>
							<div className="fw-bold mb-2 fs-4">{formClienteIndex !== undefined && data?.Data?.clientes && data?.Data?.clientes[formClienteIndex].clienteNome}</div>
						</Col>
						<Col>
							<div>{data?.Data?.numeroProcesso}</div>
						</Col>
					</Col>

					<Row className="fw-bold mb-2 topbar">
						<Col>
							<div>Nome do credor</div>
						</Col>
						<Col>
							<div>Classe</div>
						</Col>
						<Col>
							<div>Crédito</div>
						</Col>
					</Row>

					{formClienteIndex !== undefined &&
						data?.Data?.clientes &&
						data?.Data?.clientes[formClienteIndex].classes.map((classe, classeIndex) => {
							return classe.credores.map((credor, index) => {
								return (
									<div key={`${classeIndex}-${index}`}>
										<AssembleiaCredoresCard data={credor} classe={classe.classeNome} />
									</div>
								);
							});
						})}

					<Button
						className="mt-4 p-3"
						variant="light"
						type="button"
						onClick={() => {
							setFormStage(0);
						}}
						style={{ width: 180 }}
					>
						Voltar
					</Button>
				</div>
			)}

			{formStage === 2 && (
				<div>
					<Col className="mb-4">
						<Col>
							<div className="fw-bold mb-2 fs-4">{formAtoIndex !== undefined && data?.Data?.atos && data?.Data?.atos[formAtoIndex].descricao}</div>
						</Col>
					</Col>

					<Row className="fw-bold mb-2 topbar">
						<Col>
							<div>Perguntas</div>
						</Col>
					</Row>

					{formAtoIndex !== undefined &&
						data?.Data?.atos &&
						data?.Data?.atos[formAtoIndex].perguntas.map((pergunta, index) => {
							return <AssembleiaPerguntasCard key={index} data={pergunta} />;
						})}
					<div>
						<Button
							className="mt-4 p-3 me-2"
							variant="light"
							title="Exibir"
							onClick={() => {
								handleFormPerguntaModal();
							}}
						>
							<IconSVG path={IconMais} width={32} height={32} className="me-2" />
							Adicionar perguntas
						</Button>

						<Button
							className="mt-4 p-3"
							variant="light"
							type="button"
							onClick={() => {
								setFormStage(0);
							}}
							style={{ width: 180 }}
						>
							Voltar
						</Button>
					</div>
				</div>
			)}

			<Modal show={formPerguntaModal} onHide={handleFecharModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>Adicionar pergunta</Modal.Title>
				</Modal.Header>

				<Modal.Body>
					{!formSaving ? (
						<div>
							<FloatingLabel controlId="pergunta" label="Pergunta" className="mb-3">
								<Form.Control
									type="text"
									placeholder="Pergunta"
									value={formPergunta.texto}
									onChange={(event) => {
										setFormPergunta((antigo) => {
											return { ...antigo, texto: event.target.value };
										});
									}}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="perguntaEmpresa" label="Empresa" className="mb-3 floating-select">
								<Select
									placeholder={"Selecione a empresa"}
									value={formPerguntaEmpresa}
									noOptionsMessage={() => {
										return "Nenhuma opção disponivel";
									}}
									options={formPerguntaEmpresaSelect}
									styles={AsyncFloatingSelectStyle}
									onChange={(value) => {
										setFormPerguntaEmpresa(value ? value : undefined);
									}}
									isClearable={true}
								/>
							</FloatingLabel>

							<FloatingLabel controlId="perguntaTag" label="Tag" className="mb-3 floating-select">
								<Select
									placeholder={"Selecione a tag"}
									value={formPerguntaTag}
									noOptionsMessage={() => {
										return "Nenhuma opção disponivel";
									}}
									options={formPerguntaTagSelect}
									styles={AsyncFloatingSelectStyle}
									onChange={(value) => {
										setFormPerguntaTag(value ? value : undefined);
									}}
									isClearable={true}
								/>
							</FloatingLabel>

							{formPergunta.opcoes.map((opcao, opcaoIndex) => {
								return (
									<Row key={opcaoIndex}>
										<Col>
											<FloatingLabel label="Opção" className="mb-3">
												<Form.Control
													type="text"
													maxLength={24}
													value={opcao.texto}
													onChange={(event) => {
														setFormPergunta((antigo) => {
															let newOptions = [...antigo.opcoes];
															newOptions = newOptions.map((opcao, index) => {
																return { ...opcao, texto: opcaoIndex === index ? event.target.value : opcao.texto };
															});
															return { ...antigo, opcoes: newOptions };
														});
													}}
													disabled={!formPerguntaPersonalizar}
												/>
											</FloatingLabel>
										</Col>

										<Col md="auto">
											<Button
												className="mb-3 p-3 d-flex align-items-center"
												variant="light"
												type="button"
												onClick={() => {
													handleOpcaoRemove(opcaoIndex);
												}}
												disabled={!formPerguntaPersonalizar}
											>
												<IconSVG path={IconFechar} width={24} height={24} />
											</Button>
										</Col>
									</Row>
								);
							})}
							{formPerguntaPersonalizar && (
								<div className="px-2 mb-3 text-muted small">A palavra reservada 'Abstenção' é usada pelo sistema para ser considerada no cálculo do quorum</div>
							)}

							<div className="text-center">
								{!formPerguntaPersonalizar ? (
									<Button variant="light" onClick={handlePersonalizar}>
										<IconSVG path={IconEditar} width={32} height={32} className="me-2 ms-2" />
										Personalizar
									</Button>
								) : (
									<Button variant="light" onClick={handleNovaOpcao}>
										<IconSVG path={IconMais} width={32} height={32} className="me-2 ms-2" />
										Adicionar opção
									</Button>
								)}
							</div>
						</div>
					) : (
						<div className="text-center">
							<Spinner />
						</div>
					)}
				</Modal.Body>
				{!formSaving && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleFecharModal} style={{ width: 100 }}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleSalvarPergunta}>
							Salvar
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</Layout>
	);
}
