import { BrowserRouter, Route, Routes } from "react-router-dom";

import "./App.scss";

import AuthProvider from "./context/AuthContext";
import RouteAuth from "./components/RouteAuth";
import ToastProvider from "./context/ToastContext";

import Login from "./screens/Login";
import DocumentoAssinado from "./screens/DocumentoAssinado";
import Dashboard from "./screens/Dashboard";
import Chamada from "./screens/Chamada";
import ChamadaConvidado from "./screens/ChamadaConvidado";
import Assembleias from "./screens/Assembleias";
import Assembleia from "./screens/Assembleia";
import AssembleiaFormulario from "./screens/AssembleiaFormulario";
import AssembleiasPublicas from "./screens/AssembleiasPublicas";
import AssembleiasCredenciadas from "./screens/AssembleiasCredenciadas";
import AssembleiaCredenciada from "./screens/AssembleiaCredenciada";
import AssembleiaCredenciamentoFormulario from "./screens/AssembleiaCredenciamentoFormulario";
import Homologacoes from "./screens/Homologacoes";
import Homologacao from "./screens/Homologacao";
import Alertas from "./screens/Alertas";
import AlertaFormulario from "./screens/AlertaFormulario";
import Acessos from "./screens/Acessos";
import Grupos from "./screens/Grupos";
import GrupoFormulario from "./screens/GrupoFormulario";
import Usuarios from "./screens/Usuarios";
import UsuarioFormulario from "./screens/UsuarioFormulario";
import ConfiguracoesEmpresas from "./screens/ConfiguracoesEmpresas";
import ConfiguracaoFormulario from "./screens/ConfiguracaoFormulario";
import PessoaFormulario from "./screens/PessoaFormulario";

function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<AuthProvider>
				<ToastProvider>
					<Routes>
						<Route path="/" element={<Login />}>
							<Route index element={<Login />} />
							<Route path=":token" element={<Login />} />
						</Route>
						<Route path="/documentoAssinado" element={<DocumentoAssinado />} />
						<Route
							path="/dashboard"
							element={
								<RouteAuth>
									<Dashboard />
								</RouteAuth>
							}
						/>
						<Route
							path="/chamada/:assembleiaId/:atoId"
							element={
								<RouteAuth>
									<Chamada />
								</RouteAuth>
							}
						/>
						<Route path="/chamadaConvidado" element={<ChamadaConvidado />} />
						<Route
							path="/assembleias"
							element={
								<RouteAuth>
									<Assembleias />
								</RouteAuth>
							}
						/>
						<Route
							path="/assembleia/:id"
							element={
								<RouteAuth>
									<Assembleia />
								</RouteAuth>
							}
						/>
						<Route
							path="/assembleiaFormulario"
							element={
								<RouteAuth>
									<AssembleiaFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<AssembleiaFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<AssembleiaFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route path="/assembleiasPublicas/:status" element={<AssembleiasPublicas />} />
						<Route
							path="/assembleiasCredenciadas"
							element={
								<RouteAuth>
									<AssembleiasCredenciadas />
								</RouteAuth>
							}
						/>
						<Route
							path="/assembleiaCredenciada/:id"
							element={
								<RouteAuth>
									<AssembleiaCredenciada />
								</RouteAuth>
							}
						/>
						<Route
							path="/assembleiaCredenciamentoFormulario"
							element={
								<RouteAuth>
									<AssembleiaCredenciamentoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<AssembleiaCredenciamentoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<AssembleiaCredenciamentoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/homologacoes"
							element={
								<RouteAuth>
									<Homologacoes />
								</RouteAuth>
							}
						/>
						<Route
							path="/homologacao/:id"
							element={
								<RouteAuth>
									<Homologacao />
								</RouteAuth>
							}
						/>
						<Route
							path="/alertas"
							element={
								<RouteAuth>
									<Alertas />
								</RouteAuth>
							}
						/>
						<Route
							path="/alertaFormulario"
							element={
								<RouteAuth>
									<AlertaFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<AlertaFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<AlertaFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/acessos"
							element={
								<RouteAuth>
									<Acessos />
								</RouteAuth>
							}
						/>
						<Route
							path="/grupos"
							element={
								<RouteAuth>
									<Grupos />
								</RouteAuth>
							}
						/>
						<Route
							path="/grupoFormulario"
							element={
								<RouteAuth>
									<GrupoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<GrupoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<GrupoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/usuarios"
							element={
								<RouteAuth>
									<Usuarios />
								</RouteAuth>
							}
						/>
						<Route
							path="/usuarioFormulario"
							element={
								<RouteAuth>
									<UsuarioFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<UsuarioFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<UsuarioFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/configuracoesEmpresas"
							element={
								<RouteAuth>
									<ConfiguracoesEmpresas />
								</RouteAuth>
							}
						/>
						<Route
							path="/configuracaoFormulario"
							element={
								<RouteAuth>
									<ConfiguracaoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<ConfiguracaoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":empresaId"
								element={
									<RouteAuth>
										<ConfiguracaoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/pessoaFormulario"
							element={
								<RouteAuth>
									<PessoaFormulario />
								</RouteAuth>
							}
						/>
					</Routes>
				</ToastProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;
